import type { Measure, ParsedOnsetString } from './Signature';
import type { Duration, Onset } from './Unit';

export interface MeasureMapEntry {
  /**
    * Any unique string to identify this object.
    * @type {string}
      @see {@link https://raw.githubusercontent.com/measure-map/specification/main/measure.schema.json/properties/ID}
      @example
      "1"
      "m20"
      "https://dme.mozarteum.at/movi/navigator/155/001/01/20/nma"

  */
  id?: string;
  /**
   * A simple count of measure units in the described source, using natural numbers starting with 1.
    @type {integer}
    @see {@link https://raw.githubusercontent.com/measure-map/specification/main/measure.schema.json/properties/count}
    @range: ≥ 1

   */
  count: number;
  /**
   * The symbolic time to have elapsed since the start of the source, measured in quarter notes.
    @type {number}
    @see {@link https://raw.githubusercontent.com/measure-map/specification/main/measure.schema.json/properties/qstamp}
    @range: ≥ 0
    @example
    0
    16.5
    20.66667
   */
  qstamp: number;
  /**
   * A number assigned to this measure, which typically follows musical convention,
   * for instance starting with natural numbers (1, 2, 3...),
   * except in the case of anacruses which start instead on (0, 1, 2...).
    @type {integer}
    @see {@link https://raw.githubusercontent.com/measure-map/specification/main/measure.schema.json/properties/number}
    @range: ≥ 0
   */
  number: number;
  /**
   * A label for the measure. Typically used for distinguishing between measures with the
   * same number (as in '16a', '16b', '16c') or rehearsal marks.
    @type {string}
    @see {@link https://raw.githubusercontent.com/measure-map/specification/main/measure.schema.json/properties/name}
    @example
    "1"
    "16a"
    "H"
   */
  name?: string;
  /**
   * A label for the time signature. Typically this takes the form of `/' but can be an arbitrary
   * string as long or 'null' as long as actual_length is specified.
    @type {(string|null)}
    @see {@link https://raw.githubusercontent.com/measure-map/specification/main/measure.schema.json/properties/time_signature}
    @example
    "3/8"
    "C"
    null
   */
  time_signature: `${number}/${number}`;
  /**
   * The default duration that corresponds to the given 'time_signature', in quarter notes.
    @type {(number|null)}
    @see {@link https://raw.githubusercontent.com/measure-map/specification/main/measure.schema.json/properties/nominal_length}
    @range: ≥ 0
    @example
    1.5
    4
    6
    null
   */
  nominal_length: number;
  /**
   * The actual duration of the measure, in quarter notes.
    @type {number}
    @see {@link https://raw.githubusercontent.com/measure-map/specification/main/measure.schema.json/properties/actual_length}
    @range: > 0
    @example
    0.25
    4
    6
   */
  actual_length: number;
  /**
   * Typical usage is with the bool type, with 'true' indicating
   * a start repeat at the beginning of the measure.
    @type {(boolean|number)}
    @default false
    @see {@link https://raw.githubusercontent.com/measure-map/specification/main/measure.schema.json/properties/start_repeat}
   */
  start_repeat: boolean | number;
  /**
   * Typical usage is with the bool type, with 'true' indicating
   * an end repeat at the end of the measure.
    @type {(boolean|number)}
    @default false
    @see {@link https://raw.githubusercontent.com/measure-map/specification/main/measure.schema.json/properties/end_repeat}
   */
  end_repeat: boolean | number;
  /**
   * The 'ID' strings or 'count' integers that correspond to all measures that can follow this one.
   * The corresponding field needs to be present in the MeasureMap.
    @type {Array.<string|integer>}
    @see {@link https://raw.githubusercontent.com/measure-map/specification/main/measure.schema.json/properties/next}
   */
  next: Array<number|string>;
}

export type TMeasureMap = Array<MeasureMapEntry>;

const EPSILON = 0.01;

export default class MeasureMap {
  private measureMap: TMeasureMap = [];

  constructor(baseMap: TMeasureMap) {
    for (let i = 0; i < baseMap.length; i += 1) {
      this.measureMap.push(baseMap[i]);
      if (baseMap[i + 1]) {
        for (let j = baseMap[i].count + 1; j < baseMap[i + 1].count; j += 1) {
          this.measureMap.push({
            ...baseMap[i],
            count: j,
            number: j,
            qstamp: this.measureMap[this.measureMap.length - 1].qstamp + baseMap[i].nominal_length,
            next: [j + 1],
          });
        }
      }
    }
  }

  private at(index: number): MeasureMapEntry {
    if (index < this.size()) {
      return this.measureMap[index];
    }

    const extrapolatedMeasure = { ...this.measureMap[this.size() - 1] };
    extrapolatedMeasure.count = index;
    extrapolatedMeasure.number = index + 1;
    const delta = index - (this.size() - 1);
    extrapolatedMeasure.qstamp += (delta * extrapolatedMeasure.actual_length);

    return extrapolatedMeasure;
  }

  private findByNumber(value: number): MeasureMapEntry | null {
    const founded = false;
    let start = 0;
    let end = this.size() - 1;
    while (!founded && start <= end) {
      const median = Math.floor(Math.abs(start + end) / 2);
      const currentEntry = this.measureMap[median];
      if (currentEntry === undefined) return null;
      if (currentEntry.number === value) {
        return currentEntry;
      } if (value > currentEntry.number) {
        start = median + 1;
      } else {
        end = median - 1;
      }
    }
    return null;
  }

  private findByName(value: string) : MeasureMapEntry | null {
    return this.measureMap.find((e) => e.name === value) || null;
  }

  public size(): number {
    return this.measureMap.length;
  }

  public getMeasureFromOnset(value: Onset): number {
    const measureOffset = 0;
    return this.getMeasure(value + measureOffset).measure;
  }

  public measureToOnset(measure: number | string, byName = false): Onset {
    let entry: MeasureMapEntry | null;
    if (byName) {
      entry = this.findByName(measure as string);
    } else {
      entry = this.findByNumber(measure as number);
    }
    if (entry === null) {
      return this.at(
        byName ? parseInt(measure as string, 10) - 1 : (measure as number) - 1,
      ).qstamp as Onset;
    }
    return entry.qstamp as Onset;
  }

  public parse(value: ParsedOnsetString): number {
    const { measure, num, denum } = value;

    const measureAsOnset = this.measureToOnset(measure);
    const remainder = num / denum;

    const onset = measureAsOnset + remainder * 4;

    if (Number.isFinite(onset) === false) {
      throw new Error('BadFractionalString');
    }

    return onset;
  }

  public getDuration(
    value: number,
    startingOnset: Onset,
  ): Measure {
    let measureMapEntry: MeasureMapEntry = this.at(0);
    let startMeasureIndex = 0;

    let duration: Duration = 0 as Duration;

    for (let i = 0; this.at(i).qstamp <= startingOnset; i += 1) {
      startMeasureIndex = i;
    }
    measureMapEntry = this.at(startMeasureIndex);

    const startOnBar = measureMapEntry.qstamp === startingOnset;

    let measure = 0;
    let remainder = 0;

    if (!startOnBar) {
      const prevMeasureMapEntry = startMeasureIndex > 0
        ? this.at(startMeasureIndex - 1) : measureMapEntry;
      const startDelta = ((startingOnset - this.measureIncrement(prevMeasureMapEntry))
        - measureMapEntry.qstamp) / this.measureIncrement(prevMeasureMapEntry);
      duration = Math.abs(startDelta) - 1 as Duration;
    }

    let reachedOffset = false;
    let endMeasureMapEntry: MeasureMapEntry = this.at(startMeasureIndex);
    for (let i = startMeasureIndex; !reachedOffset; i += 1) {
      const currentMeasure = this.at(i);
      if (
        currentMeasure.qstamp >= (
          startingOnset
          + value - this.measureIncrement(currentMeasure))
      ) {
        const delta = (currentMeasure.qstamp
          - (startingOnset + value)) / this.measureIncrement(currentMeasure);
        duration = duration - (delta) as Duration;
        reachedOffset = true;
        endMeasureMapEntry = currentMeasure;
        measure = Math.floor(measure + EPSILON);
        remainder = Math.max(duration - measure, 0);
      } else if (i === 0 || (i > 0 && currentMeasure.number !== this.at(i - 1).number)) {
        measure += 1;
        duration = duration + 1 as Duration;
      }
    }

    if (value === 0) {
      remainder = 0;
    }

    const timeSignature = this.getTimeSignatureByMeasure(endMeasureMapEntry);

    const measureOffset = (timeSignature.numerator * timeSignature.timeSignature);

    return {
      measure: remainder >= 0.75 || remainder === 0 ? measure + 1 : measure,
      remainder,
      measureOffset,
      timeSignature: timeSignature.timeSignature,
      denominator: timeSignature.denominator,
      numerator: timeSignature.numerator,
      measureMapEntry: endMeasureMapEntry,
    };
  }

  public getMeasure(
    value: number,
  ): Measure {
    let founded = false;
    let measureFloat = value / (this.measureIncrement(this.at(0)));
    let measureMapEntry: MeasureMapEntry = this.at(0);
    for (let i = 0; i < this.size(); i += 1) {
      const currentMeasure = this.at(i);
      if (currentMeasure.qstamp >= value && i > 0) {
        if (i < 2 || currentMeasure.qstamp === value) {
          measureFloat = currentMeasure.number
            - this.computeDelta(value, currentMeasure);
          measureMapEntry = currentMeasure;
        } else {
          const lastMeasure = this.at(i - 1);
          measureFloat = lastMeasure.number
            + ((value - lastMeasure.qstamp) / this.measureIncrement(lastMeasure));
          measureMapEntry = lastMeasure;
        }

        founded = true;
        break;
      }
    }

    if (!founded && this.size() > 1) {
      // this.setTimeSignatureByMeasureMap(this.at(this.size() - 1));
      const lastEntry = this.at(this.size() - 1);

      const delta = (value - this.measureIncrement(lastEntry)) - lastEntry.qstamp;

      measureFloat = lastEntry.number + delta / 4;
      measureMapEntry = lastEntry;
    }

    // Same number, rounded down
    const measure = Math.floor(measureFloat + EPSILON);
    // The remainder, between 0 and 1
    const remainder = Math.max(measureFloat - measure, 0);

    const timeSignature = this.getTimeSignatureByMeasure(measureMapEntry);

    const measureOffset = (timeSignature.numerator * timeSignature.timeSignature);

    return {
      measure: founded ? measure : measure + 1,
      remainder,
      measureOffset,
      timeSignature: timeSignature.timeSignature,
      denominator: timeSignature.denominator,
      numerator: timeSignature.numerator,
      measureMapEntry,
    };
  }

  private computeDelta(
    value: number,
    measureMapEntry: MeasureMapEntry,
  ): number {
    // this.setTimeSignatureByMeasureMap(prevMeasureMapEntry);
    const delta = (measureMapEntry.qstamp - value) / this.measureIncrement(measureMapEntry);

    return delta;
  }

  private getTimeSignatureByMeasure(measureMapEntry: MeasureMapEntry): {
    timeSignature: number,
    numerator: number,
    denominator: number,
  } {
    const numerator = parseInt(measureMapEntry.time_signature.split('/')[0], 10);
    const denominator = parseInt(measureMapEntry.time_signature.split('/')[1], 10);

    return {
      timeSignature: numerator / denominator,
      numerator,
      denominator,
    };
  }

  private measureIncrement(measureMapEntry: MeasureMapEntry): Duration {
    const timeSignature = this.getTimeSignatureByMeasure(measureMapEntry);
    // return (timeSignature) as Duration;
    return (timeSignature.timeSignature * 4) as Duration;
  }
}
