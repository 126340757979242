import type { Module } from 'vuex';
import type { DefaultStoreState } from './default.state';
import { state } from './default.state';
import { mutations } from './default.mutations';
import { actions } from './default.actions';

export const module: Module<DefaultStoreState, DefaultStoreState> = {
  state,
  mutations,
  actions,
};
