export default class Zoom {
  #exponent = 0;

  value = 1;

  #base: number;

  maxExponent: number;

  minExponent: number;

  public get base(): number {
    return this.#base;
  }

  public get exponent(): number {
    return this.#exponent;
  }

  public set exponent(value: number) {
    let bounded = Math.min(Math.max(value, this.minExponent), this.maxExponent);

    if (!bounded) bounded = 0;

    this.#exponent = bounded;
    this.value = this.#base ** this.#exponent;
  }

  public getExponentForValue(value: number): number {
    // value = base ^ exponent
    // therefore
    // exponent = ln(value) / ln(base)
    return Math.log(value) / Math.log(this.#base);
  }

  constructor(base = Math.sqrt(2), maxExponent = 5, minExponent = -5) {
    this.#base = base;
    this.maxExponent = maxExponent;
    this.minExponent = minExponent;
  }
}
