/* eslint-disable global-require */
import Vue from 'vue';
import type { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import VueRouter from 'vue-router';
import { distClient, localClient } from './clients';

Vue.use(VueRouter);

// Create the component records for vue-router
// Each route can have any component as a sidebar and as a page

// default sidebar
const sidebar = () => import('@/components/navigation/Sidebar.vue');
// collab score sidebar
const collabSidebar = () => import('@/components/navigation/CollabScoreSidebar.vue');

// The main dezrann page components
const analysisPageComponents = {
  default: () => import('@/views/AnalysisPage.vue'),
  sidebar,
};

const syncPageComponents = {
  default: () => import('@/views/SyncPage.vue'),
  sidebar,
};

const collabPageComponents = {
  default: () => import('@/views/SyncPage.vue'),
  sidebar: collabSidebar,
};

const aboutPageComponents = {
  default: () => import('@/vuejs/views/AboutPage.vue'),
  sidebar,
};

const helpPageComponents = {
  default: () => import('@/vuejs/views/HelpPage.vue'),
  sidebar,
};

const missingPiecePageComponents = {
  default: () => import('@/vuejs/views/MissingPiecePage.vue'),
  sidebar,
};

const missingPageComponents = {
  default: () => import('@/vuejs/views/MissingPage.vue'),
  sidebar,
};

const corpusTable = {
  default: () => import('@/vuejs/views/CorpusTableVue.vue'),
  sidebar,
};

const showcaseCorpus = {
  default: () => import('@/vuejs/views/ShowcasePage.vue'),
  sidebar,
};

const collabscorePageComponents = {
  default: () => import('@/views/CollabscorePage.vue'),
  sidebar: collabSidebar,
};

// TODO: Since data is passed to views from
// * $route.params
// * $route.query
// * $route.meta
// see if it's not possible to move back to props: https://router.vuejs.org/guide/essentials/passing-props.html#boolean-mode

// Declare the routes

const routes: Array<RouteConfig> = [
  // Piece Pages
  { // The main piece page, loads data from corpus
    path: '/~/:piecePath(.+)',
    name: 'Dezrann App',
    components: analysisPageComponents,
    meta: { client: distClient },
  },
  { // The local piece page, loads data from vue server
    path: '/~~/:piecePath(.+)',
    name: 'Dezrann Local App',
    components: analysisPageComponents,
    meta: { client: localClient },
  },
  { // The synchronization tool for corpus pieces
    path: '/sync/:piecePath(.+)',
    name: 'Synchronization Editor',
    components: syncPageComponents,
    meta: { client: distClient },
  },
  { // The synchronization tool for local pieces
    path: '/local-sync/:piecePath(.+)',
    name: 'Local Synchronization Editor',
    components: syncPageComponents,
    meta: { client: localClient },
  },
  { // Collabscore corpus branding
    path: '/collabscore/explore/:corpusId*',
    name: 'Collabscore corpus',
    components: corpusTable,
    meta: { client: distClient, appName: 'collabscore' },
  },

  { // Collabscore branding
    path: '/collabscore/:piecePath(.+)',
    name: 'Collabscore',
    components: collabscorePageComponents,
    meta: { client: distClient },
  },
  { // The synchronization tool for corpus pieces, with the collab sidebar
    path: '/collabscore-sync/:piecePath(.+)',
    name: 'CollabScore Synchronization Editor',
    components: collabPageComponents,
    meta: { client: distClient },
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
      if (to.path.includes(':') && to.query.neuma_id === undefined) {
        const splittedPath = to.path.split('/');
        const neumaId = splittedPath[splittedPath.length - 1];
        return next({ path: `/collabscore/collabscore/${neumaId.replaceAll(':', '-')}?neuma_id=${neumaId}`, query: to.query });
      }
      return next();
    },
  },
  { // The synchronization tool for local pieces, with the collab sidebar
    path: '/local-collabscore-sync/:piecePath(.+)',
    name: 'Local CollabScore Synchronization Editor',
    components: collabPageComponents,
    meta: { client: localClient },
  },
  // Meta pages
  { // Contains general info on dezrann
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: aboutPageComponents,
  },
  { // Contains help and keyboard shortcuts
    path: '/help',
    name: 'Help',
    // route level code-splitting
    // this generates a separate chunk (help.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: helpPageComponents,
  },
  // Redirect links
  {
    path: '/',
    redirect: () => ({
      name: 'Showcase Corpus',
    }),
  },
  // Fallback pages
  { // Piece fallback page, redirected directly from PieceRouter using name
    path: '/~/*',
    alias: '/sync/*',
    name: 'Missing Piece',
    components: missingPiecePageComponents,
  },
  { // Local piece fallback page, redirected directly from PieceRouter using name
    path: '/~~/*',
    alias: '/local-sync/*',
    name: 'Missing Local Piece',
    components: missingPiecePageComponents,
  },

  // CollabScore fallback pages
  // Redirects to collab score demo page
  {
    path: '/collab-score/',
    alias: ['/local-collab/*', '/collab/*'],
    redirect: '/local-collab/marenzio',
  },

  // General fallback page
  { // General fallback page, uses alias to remove warning of missing param
    path: '/404',
    alias: '*',
    name: 'Missing Page',
    components: missingPageComponents,
  },

  {
    path: '/explore/:corpusId*',
    alias: '/corpusVue',
    name: 'CorpusTable',
    components: corpusTable,
    meta: { client: distClient },
  },
  {
    path: '/corpora',
    name: 'Showcase Corpus',
    components: showcaseCorpus,
    meta: { client: distClient },
  },
  {
    path: '/neuma/:pieceId',
    redirect: (to) => ({ path: `/collabscore/collabscore/${to.params.pieceId}` }),
  },
];

const collabGallicaMapping: { [gallicaId: string]: string; } = {
  bpt6k11620473: 'all-collabscore-saintsaens-ref-C006_0',
  bpt6k3821202: 'all-collabscore-saintsaens-ref-C036_0',
  bpt6k382117c: 'all-collabscore-saintsaens-ref-C046_0',
  bpt6k1162049x: 'all-collabscore-saintsaens-ref-C079_0',
  bpt6k1162028r: 'all-collabscore-saintsaens-ref-C080_0',
  bpt6k1162023p: 'all-collabscore-saintsaens-ref-C141_0',
  bpt6k1174892k: 'all-collabscore-saintsaens-ref-C452_0',
  bpt6k1166887m: 'all-collabscore-saintsaens-ref-C455_0',
  btv1b52502625c: 'all-collabscore-saintsaens-ref-R171_0',
};

const gallicaPrefix = '/gallica/';
const collabPrefix = '/collabscore/collabscore/';
const collabGallicaRoutes = Object.keys(collabGallicaMapping).map((key: string) => ({
  path: `${gallicaPrefix}${key}`,
  redirect: `${collabPrefix}${collabGallicaMapping[key]}`,
}));

const router = new VueRouter({
  mode: 'history', // Allow the app to be truly single page
  base: process.env.BASE_URL, // Allow the app to run as part of a domain
  routes: [...collabGallicaRoutes, ...routes],
});

export default router;
