import type { ErrorObject as AjvError } from 'ajv';
import type { AxiosError } from 'axios';

export enum ClientStatus {
  Ok = 'Ok',
  ParsingError = 'ParsingError',
  NoResponse = 'NoResponse',
  Missing = 'Missing',
  Unauth = 'Unauth',
  Other = 'Other',
}

export type ClientStatusErr = Exclude<ClientStatus, ClientStatus.Ok>;

interface ResponseBase<T> {
  isOk(): boolean;
  isErr(): boolean;
  status: ClientStatus;
  data: T;
}

export interface ResponseOk<T> extends ResponseBase<T> {
  isOk(): this is ResponseOk<T>;
  isErr(): false;
  status: ClientStatus.Ok;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ResponseErrorBase<T = any> extends ResponseBase<T>, Error {
  isOk(): false;
  isErr(): this is ResponseErrorBase<T>;
  status: ClientStatusErr;
  /**
   * @throws this
   */
  data: T;
  error: unknown,
  message: string,

  cast<U>(): U;
}

export interface ResponseNoServer<T> extends ResponseErrorBase<T> {
  status: ClientStatus.NoResponse;
  error: AxiosError,
}

export interface ResponseClientError<T> extends ResponseErrorBase<T> {
  status: ClientStatus.Missing | ClientStatus.Unauth | ClientStatus.Other;
  realStatus: number,
  error: AxiosError,
}

export interface ResponseParsingError<T> extends ResponseErrorBase<T> {
  status: ClientStatus.ParsingError;
  error: AjvError | Error,
}

export type HttpResponse<T> =
  ResponseOk<T> |
  ResponseClientError<T> |
  ResponseNoServer<T>;

export type Response<T> =
  ResponseOk<T> |
  ResponseClientError<T> |
  ResponseNoServer<T> |
  ResponseParsingError<T>;

export type ResponseError<T = unknown> =
  ResponseClientError<T> |
  ResponseNoServer<T> |
  ResponseParsingError<T>;

export class ClientResponse<T> {
  static isClientResponse(res: unknown): res is Response<unknown> {
    return res instanceof ClientResponse;
  }

  static isClientError<U>(res: unknown): res is ResponseError<U> {
    return res instanceof ClientResponse && res.isErr();
  }

  static isResponseOk(res: unknown): res is ResponseOk<unknown> {
    return res instanceof ClientResponse && res.isOk();
  }

  isOk(): this is ResponseOk<T> {
    return this.status === ClientStatus.Ok;
  }

  isErr(): this is ResponseError<T> {
    return this.status !== ClientStatus.Ok;
  }

  status: ClientStatus;

  private successData?: T;

  get data(): T {
    if (this.isOk()) return this.successData as T;
    // eslint-disable-next-line no-throw-literal
    throw this;
  }

  cast<U>(): ResponseError<U> {
    if (this.isOk()) throw new Error('Cannot cast successful error');
    return this as unknown as ResponseError<U>;
  }

  name?: string;

  error?: unknown;

  message?: string;

  constructor(status: ClientStatus.Ok, data: T);

  constructor(status: Exclude<ClientStatus, ClientStatus.Ok>, error: Error);

  constructor(status: ClientStatus, content: T | Error) {
    this.status = status;
    if (status === ClientStatus.Ok) {
      this.successData = content as T;
    } else {
      // eslint-disable-next-line no-console
      console.warn(`New ${status}Client Error: `, content);
      const error = content as Error;
      this.name = error.name;
      this.message = error.message;
      this.error = error;
    }
  }

  static statusToClientStatus(status: number | undefined): ClientStatus {
    if (status === undefined) return ClientStatus.NoResponse;
    if (status >= 200 && status < 300) return ClientStatus.Ok;
    if (status === 404) return ClientStatus.Missing;
    if (status === 403 || status === 401) return ClientStatus.Unauth;
    return ClientStatus.Other;
  }

  static fromAxios<U>(error: AxiosError): HttpResponse<U> {
    const status = error.response?.status;
    const clientStatus = ClientResponse.statusToClientStatus(status) as ClientStatusErr;
    return new ClientResponse<U>(clientStatus, error) as HttpResponse<U>;
  }

  static fromParsingError<U>(error: Error): ResponseParsingError<U> {
    const status = ClientStatus.ParsingError;
    return new ClientResponse<U>(status, error) as ResponseParsingError<U>;
  }

  static fromData<U>(data: U): ResponseOk<U> {
    return new ClientResponse<U>(ClientStatus.Ok, data) as ResponseOk<U>;
  }
}
