import { Source } from '@/data';
import type {
  SourceType, Staff, Unit, Pixel, Audio,
} from '@/data';
import type { Image3DPageData } from '@/data/Source';

/**
 * An image file object
 *
 * Contains:
 * * the relative path to an image file
 * * a time conversion object
 *
 * For score images, the conversion should be onset -> pixel
 *
 * For wave images, the conversion should be second -> pixel
 */
export interface FileFormat {
  image: string;
  positions: string;
  type: SourceType;
  info?: string;
  name?: string;
  license?: string;
  contributors?: Record<string, string>;
  source?: string;
  year?: string;
}

export interface ImageFileFormat extends FileFormat {
  type: SourceType.Score;
}

export interface Image3DFileFormat extends FileFormat {
  type: SourceType.Image3D;
}

export interface AudioImageFileFormat extends FileFormat {
  type: SourceType.AudioImage;
}

export interface ImageDimensions {
  height: Pixel,
  width: Pixel,
}

export default class SourceParser extends Source {
  static fromFormat(
    format: FileFormat,
    dim: ImageDimensions,
    pixelUnit: Unit<Pixel>,
    staves: Staff[],
    imageUrl: string,
    track?: Audio,
    pages?: Array<Image3DPageData>,
  ): Source {
    try {
      const type = String(format.type || '') as SourceType;

      const info = String(format.info || '');

      const name = String(format.name || '');

      const license = String(format.license || '');

      const source = format.source || undefined;

      const year = format.year || undefined;

      return new Source({
        pixelUnit,
        staves,
        imageUrl,
        type,
        info,
        name,
        track,
        license,
        pages,
        source,
        year,
        contributors: format.contributors,
        ...dim,
      });
    } catch (e) {
      const error = e as Error;
      throw new Error(`Corrupted Source: ${error.message}`);
    }
  }
}
