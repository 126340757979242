import { lineIds } from '@/data/Staff';
import type { TypeArg } from './FilterGroup';
import FilterGroup from './FilterGroup';

/**
 * All the legacy and modern dezrann types
 */
const defaultTypesDecl: TypeArg[] = [
  {
    name: 'Pattern', line: lineIds.bot2, color: 'auto',
  },
  {
    name: 'Theme', line: lineIds.bot2, color: '#aa0033',
  },
  {
    name: 'Key', line: lineIds.bot2, color: '#330088',
  },
  {
    name: 'Modulation', line: lineIds.bot2, color: '#4400ee',
  },
  {
    name: 'Harmony', line: lineIds.bot1, color: '#4400bb',
  },
  {
    name: 'Pedal', line: lineIds.bot1, color: '#335555',
  },
  {
    name: 'Cadence', line: lineIds.all, color: '#ff00ff',
  },
  {
    name: 'Harmonic sequence', line: lineIds.bot3, color: '#ff8800',
  },
  {
    name: 'Texture', line: lineIds.bot3, color: '#22ff22',
  },
  {
    name: 'Structure', line: lineIds.top1, color: '#ffff00',
  },
  {
    name: 'Positive Feedback', color: '#99ff99', fontStyle: 'italic',
  },
  {
    name: 'Feedback', color: '#dddddd', fontStyle: 'italic',
  },
  {
    name: 'Negative Feedback', color: '#ff9999', fontStyle: 'italic',
  },
  {
    name: 'Comment', line: lineIds.bot3, color: '#dddddd', fontStyle: 'italic',
  },
];

/**
 * The default filters common to all analysis files
 *
 * A set of useful types with unique colors and css properties
 */
export const defaultTypes = FilterGroup.fromTypes(defaultTypesDecl);
