import type { MutationTree } from 'vuex';
import type { Piece } from '@/data';
import type { DefaultStoreState } from './default.state';

export const mutations: MutationTree<DefaultStoreState> = {
  updatePrimaryColor(state, color: string) {
    state.primaryColor = color;
  },
  updateSecondaryColor(state, color: string) {
    state.secondaryColor = color;
  },
  updateCorpus(state, corpus: Piece[]) {
    state.corpus = corpus;
  },
  updateCorpusId(state, id: string) {
    state.corpusId = id;
  },
  updateIndexCurrentPiece(state, index: number) {
    state.indexCurrentPiece = index;
  },
  updateAllowedToPlay(state, allowedToPlay: boolean) {
    state.allowedToPlay = allowedToPlay;
  },
  updateDebugMode(state, debug: boolean) {
    state.debugMode = debug;
  },
  updateLockEditor(state, lock: boolean) {
    state.lockEditor = lock;
  },
  hideSnack(state) {
    state.snack.show = false;
  },
  showSnack(state) {
    state.snack.show = true;
  },
  updateSnackText(state, text: string) {
    state.snack.text = text;
  },
};
