/* eslint-disable no-bitwise */
import type { Image3DPageData } from './Source';
import type Staff from './Staff';
import type { Pixel } from './Unit';

export interface DeMuxPixel {
  page: number;
  row: number;
  x: Pixel;
}

export default class PixelUtils {
  static mux(pixel: DeMuxPixel): Pixel {
    return ((pixel.page << 24) | (pixel.row << 16) | pixel.x) as Pixel;
  }

  static deMux(source: Pixel): DeMuxPixel {
    return {
      page: source >> 24,
      row: (source >> 16) & 0xFF,
      x: (source & 0xFFFF) as Pixel,
    };
  }

  static getStaffByPosition(pages: Array<Image3DPageData>, staffId: number): Staff | null {
    return pages[0].rows[0].staves.find((s) => s.id === staffId) || null;
  }

  static getYAsPixel(pages: Array<Image3DPageData>, source: Pixel): Pixel {
    if (!pages) return source;

    const demuxPixel = PixelUtils.deMux(source);
    const baseHeight = pages[0].height * demuxPixel.page;
    const rowY = pages[demuxPixel.page].rows[demuxPixel.row].y;
    const rowHeight = pages[demuxPixel.page].rows[demuxPixel.row].height;
    return baseHeight + rowY + rowHeight / 2 as Pixel;
  }

  static getMuxPixelFromPosition(pages: Array<Image3DPageData>, x: Pixel, y: Pixel): Pixel {
    let page = PixelUtils.getPageIndex(pages, y);
    if (!pages) {
      page = 0;
    } else if (page >= pages.length) page = pages.length - 1;

    let row = 0;
    if (pages) {
      row = PixelUtils.nearestRowIndexFromPosition(pages, y);
    }
    return PixelUtils.mux({ page, row, x });
  }

  static getPageIndex(pages: Array<Image3DPageData>, pixelY: Pixel): number {
    return pages ? Math.ceil(pixelY / pages[0].height) - 1 : 0;
  }

  static nearestRowIndexFromPosition(pages: Array<Image3DPageData>, pixelY: Pixel): number {
    if (!pages) return 0;
    const pageHeight = pages[0].height;
    let rowIndex: number | null = null;
    for (let z = pages.length - 1; z >= 0; z -= 1) {
      for (let y = pages[z].rows.length - 1; y >= 0; y -= 1) {
        if (
          pixelY >= pages[z].rows[y].y + (pageHeight * z)
        ) {
          rowIndex = y;
          break;
        }
      }
      if (rowIndex !== null) {
        break;
      }
    }
    return rowIndex === null ? 0 : rowIndex;
  }

  static rowIndexFromPosition(pages: Array<Image3DPageData>, pixelY: Pixel): number {
    if (!pages) return 0;
    let rowIndex: number | null = null;
    for (let z = Math.ceil(pixelY / pages[0].height) - 1; z < pages.length; z += 1) {
      for (let y = 0; y < pages[z].rows.length; y += 1) {
        if (
          pixelY % pages[0].height >= pages[z].rows[y].y
          && pixelY % pages[0].height <= pages[z].rows[y].y + pages[z].rows[y].height
        ) {
          rowIndex = y;
          break;
        }
      }
      if (rowIndex !== null) {
        break;
      }
    }
    return rowIndex === null ? 0 : rowIndex;
  }
}
