import 'reflect-metadata';
import Vue from 'vue';
import VuetifyDialog from 'vuetify-dialog';
import PortalVue from 'portal-vue';
import 'vuetify-dialog/dist/vuetify-dialog.css';
import Vuex from 'vuex';

import router from '@/router';

import '@/styles/app.scss';
import '@/plugins/register-router-hooks';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import App from '@/App.vue';
import { storeOpts } from '@/store';

// Vuetify dialog
const vuetifyDialogOptions = {
  context: { vuetify, i18n },
};

Vue.use(VuetifyDialog, vuetifyDialogOptions);

// Vue Portal
Vue.use(PortalVue);

// Vuex store
Vue.use(Vuex);
const store = new Vuex.Store(storeOpts);

// Vue config
Vue.config.productionTip = false;
Vue.prototype.$store = store;
Vue.prototype.i18n = i18n;

// Init
new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app');
