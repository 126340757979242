
import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';

import type { Client } from '@/client';
import { User } from '@/client';

/**
 * The main dezrann integration
 *
 * Displays a piece analysis and lets a user modify it
 */
@Component
export default class PieceLoader extends Vue {
  @Prop({ required: true })
  client!: Client;

  @Prop({ required: true })
  storageKey!: string;

  // Load user from local storage on creation
  created() {
    const userString = localStorage.getItem(this.storageKey);
    this.client.user = User.fromString(userString);
  }

  // Store user to local storage on any change
  @Watch('client.user')
  onLogin() {
    const { user } = this.client;
    if (user) {
      localStorage.setItem(this.storageKey, user.toString());
    } else {
      localStorage.removeItem(this.storageKey);
    }
  }

  render(): null {
    return null;
  }
}
