import type {
  Onset, Pixel, Second, UnitTable,
} from './Unit';
import Unit from './Unit';

/**
 * A special case of Unit<Pixel>
 *
 * Represents the pixel unit of a spectrogram, that converts first to seconds, then to pixels
 *
 * Allows the use of the second-pixel table only
 */
export default class PixelUnitWithSeconds extends Unit<Pixel> {
  /**
   * The index of the table that converts to seconds
   */
  secondIndex: number;

  /**
   * Built a new PixelUnitWithSeconds from a second unit and a second to pixel table
   */
  private constructor(tables: UnitTable[], secondIndex: number, scale = 1) {
    super(tables, scale);
    this.secondIndex = secondIndex;
  }

  static fromAudioUnit(
    unitSecond: Unit<Second>,
    secondToPixelTable: UnitTable<Second, Pixel>,
  ): PixelUnitWithSeconds {
    // Check source unit
    if (unitSecond.tables.length < 1) throw new Error('Unit has no tables');
    // Create a new table array
    const tables = [...unitSecond.tables, secondToPixelTable];

    // Store the index of the last table
    // This corresponds to the Unit distanceFromOnset of the 'second' conversion
    // See Unit.ts
    const secondIndex = unitSecond.tables.length;

    return new PixelUnitWithSeconds(tables, secondIndex);
  }

  fromSecondToPixel(second: Second): Pixel {
    if (this.tables.length !== 2) throw new Error('PixelUnitWithSeconds is corrupted');

    return this.fromPartialToTarget(second, this.secondIndex);
  }

  fromSecondToOnset(second: Second): {
    value: Onset,
    repeat?: number | undefined,
  } {
    if (this.tables.length !== 2) throw new Error('PixelUnitWithSeconds is corrupted');

    const o = this.fromPartialToOnset(second, this.secondIndex);
    return {
      value: o.value as Onset,
      repeat: o.repeat,
    };
  }

  fromOnsetToSecond(onset: Onset, repeat: number | undefined = undefined): Second {
    if (this.tables.length !== 2) throw new Error('PixelUnitWithSeconds is corrupted');

    return this.fromOnsetToPartial(onset, this.secondIndex, repeat) as Second;
  }

  fromPixelToSecond(pixel: Pixel): Second {
    if (this.tables.length !== 2) throw new Error('PixelUnitWithSeconds is corrupted');

    return this.fromTargetToPartial(pixel, this.secondIndex) as Second;
  }

  fromOnsetToSecondToPixel(onset: Onset, repeat: number | undefined = undefined): Pixel {
    return this.fromSecondToPixel(
      this.fromOnsetToSecond(onset, repeat),
    );
  }

  getScaled(scale: number): PixelUnitWithSeconds {
    return new PixelUnitWithSeconds(this.tables, this.secondIndex, this.scale * scale);
  }

  static isUnitWithSeconds(unit: Unit<number>): unit is PixelUnitWithSeconds {
    return unit instanceof PixelUnitWithSeconds;
  }
}
