
























import {
  Component, Vue, Watch,
} from 'vue-property-decorator';
import ClientStorage from '@/components/navigation/ClientStorage.vue';
import { distClient, localClient } from '@/router/clients';

@Component({ components: { ClientStorage } })
export default class App extends Vue {
  // TODO: use user groups to check if sync editing is allowed
  // the edit piece button could be displayed from piecepage using another portal
  /**
   * If the 'edit piece' button should be displayed
   */
  // allowSync = !!process.env.VUE_APP_ALLOW_SYNC;

  /**
   * The backend client, imported here for storage
   */
  distClient = distClient;

  /**
   * The list of clients, to pass to the sidebar
   */
  clients = [distClient, localClient];

  created() {
    this.updateColors();
    document.addEventListener('keydown', this.onKeyDown);
  }

  beforeUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  @Watch('$route.path')
  updateColors() {
    const { path } = this.$route;
    if (path.startsWith('/collabscore/')) {
      this.$store.dispatch('updateColors', { primary: 'brown lighten-3', secondary: 'brown lighten-4' });
    } else if (path.startsWith('/local-sync/') || path.startsWith('/sync/')) {
      this.$store.dispatch('updateColors', { primary: 'orange', secondary: 'amber' });
    } else {
      this.$store.dispatch('updateColors', { primary: 'primary', secondary: 'secondary' });
    }
  }

  onKeyDown(e: KeyboardEvent) {
    if (e.altKey) {
      if (e.key === 'x') {
        this.$store.dispatch('updateDebugMode', !this.$store.state.debugMode);
      }
    }
  }
}

