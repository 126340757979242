import type { Response } from './ClientResponse';
import { ClientResponse } from './ClientResponse';

export function folderFormatToArray(folder: unknown): Response<string[]> {
  // Check if string array
  function isStringFormat(format: unknown[]): format is string[] {
    return format.every((f) => typeof f === 'string');
  }

  // Check if file object array
  function isObjectFormat(format: unknown[]): format is {name: string}[] {
    return format.every((f) => f
      && typeof (f as Record<string, string>).name === 'string');
  }

  if (Array.isArray(folder)) {
    if (isObjectFormat(folder)) return ClientResponse.fromData(folder.map((f) => f.name));
    if (isStringFormat(folder)) return ClientResponse.fromData(folder);
  }
  return ClientResponse.fromParsingError(new Error('Invalid Folder Format'));
}
