// Typescript definitions of the analysis formats

import type { Signature } from '@/data';

export enum LineName {
  all = 'all',
  top1 = 'top.1',
  top2 = 'top.2',
  top3 = 'top.3',
  bot1 = 'bot.1',
  bot2 = 'bot.2',
  bot3 = 'bot.3',
}

export const LineNameList = [
  LineName.all,
  LineName.top1,
  LineName.top2,
  LineName.top3,
  LineName.bot1,
  LineName.bot2,
  LineName.bot3,
] as const;

export interface LabelFormat {
  start?: number | string;
  type?: string;
  tag?: string;
  comment?: string;
  ioi?: number | string;
  duration?: number | string;
  end?: number | string;
  staff?: string;
  line?: LineName;
  layers?: string[];
}

export const FontStyleList = ['normal', 'italic'] as const;
export const FontWeightList = ['normal', 'bold'] as const;
export const FONT_SIZE_MAX = 30;
export const FONT_SIZE_MIN = 5;

export interface LabelStyleFormat {
  line?: LineName;
  color?: string;
}

export interface TextStyleFormat {
  fontStyle?: typeof FontStyleList[number];
  fontWeight?: typeof FontWeightList[number];
  fontSize?: number;
}

export interface LayoutFormat {
  // At least one layer or the type must be defined
  filter: {
    type?: string;
    tag?: string;
    layers?: string[];
  }
  style?: LabelStyleFormat,
  textStyle?: TextStyleFormat,
}

export interface AnalysisFormat {
  labels: LabelFormat[];
  meta?: {
    layout?: LayoutFormat[];
    creationButtons?: LabelFormat[];
    [key: string]: unknown;
  }
  signature?: Signature;
}

export interface AnalysisFormatNoMeta {
  labels: LabelFormat[];
  meta?: {
    layout?: LayoutFormat[];
    creationButtons?: LabelFormat[];
  }
}
