import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { loadAnalysesList, loadAnalysis, uploadAnalysis } from './analyses';
import type { HttpResponse, Response } from './ClientResponse';
import {
  ClientResponse, ClientStatus,
} from './ClientResponse';
import { loadCorpus, loadShowcaseCorpus } from './corpus';
import { folderFormatToArray } from './folder';
import type { IClient } from './IClient';
import type { User } from './User';
import { login, changePassword } from './User';
import { loadPiece, uploadSync } from './piece';
import { loadCorpusData } from './corpusData';

export class Client implements IClient {
  user: User | null = null;

  private get config(): AxiosRequestConfig {
    const headers: Record<string, string> = {};
    if (this.user) headers.authorization = `Bearer ${this.user.token}`;
    return {
      responseType: 'json',
      headers,
      timeout: 20 * 1000,
    };
  }

  baseUrl: string;

  staticRoute: string;

  authUrl: string | null;

  changePwdUrl: string | null;

  isDirectory: boolean;

  isIllegalUrlComponent(str: string): boolean {
    return !!str.match(/[^A-z0-9-_/.: ]/);
  }

  url(...components: string[]): Response<string> {
    // Check if any illegal characters were passed to the url
    const illegalIndex = components.findIndex(this.isIllegalUrlComponent);
    if (illegalIndex >= 0) {
      return new ClientResponse(
        ClientStatus.Missing,
        new Error(`Illegal character in url: '${components[illegalIndex]}'`),
      ).cast();
    }
    // Check if any url components are empty
    const emptyIndex = components.findIndex((c) => !c);
    if (emptyIndex !== -1 && emptyIndex !== components.length - 1) {
      return ClientResponse.fromParsingError(new Error('Empty url component found in url, and not at the end'));
    }

    return ClientResponse.fromData(this.baseUrl + components.join('/'));
  }

  async get<T>(url: string): Promise<HttpResponse<T>> {
    try {
      const response = await axios.get<T>(url, this.config);
      const { data } = response;
      return ClientResponse.fromData(data);
    } catch (e) {
      if (axios.isAxiosError(e)) return ClientResponse.fromAxios<T>(e);
      throw e;
    }
  }

  async getFolder(url: string): Promise<Response<string[]>> {
    try {
      const response = await axios.get<unknown>(url, this.config);
      const { data } = response;
      return folderFormatToArray(data);
    } catch (e) {
      if (axios.isAxiosError(e)) return ClientResponse.fromAxios<string[]>(e);
      if (ClientResponse.isClientError(e)) return e as HttpResponse<string[]>;
      throw e;
    }
  }

  async post<T>(
    url: string,
    postData: unknown,
    config:AxiosRequestConfig | null = null,
  ): Promise<HttpResponse<T>> {
    try {
      const response = await axios.post<T>(url, postData, { ...this.config, ...config });
      const { data } = response;
      return ClientResponse.fromData(data);
    } catch (e) {
      if (axios.isAxiosError(e)) return ClientResponse.fromAxios<T>(e);
      throw e;
    }
  }

  async put<T>(url: string, postData: unknown): Promise<HttpResponse<T>> {
    try {
      const response = await axios.put<T>(url, postData, this.config);
      const { data } = response;
      return ClientResponse.fromData(data);
    } catch (e) {
      if (axios.isAxiosError(e)) return ClientResponse.fromAxios<T>(e);
      throw e;
    }
  }

  tryParse<T>(fn: () => T): Response<T> {
    try {
      return ClientResponse.fromData(fn());
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed Parsing:', error);
      if (ClientResponse.isClientError<T>(error)) return error;
      return ClientResponse.fromParsingError<T>(error as Error);
    }
  }

  get canLogin(): boolean {
    return !!this.login;
  }

  get canUpload(): boolean {
    // isDirectory is only true for the local client
    // TODO: clean this in the case of new variants of the client
    return !this.isDirectory;
  }

  loadPiece = loadPiece.bind(this);

  loadCorpus = loadCorpus.bind(this);

  loadCorpusData = loadCorpusData.bind(this);

  loadShowcaseCorpus = loadShowcaseCorpus.bind(this);

  loadAnalysesList = loadAnalysesList.bind(this);

  loadAnalysis = loadAnalysis.bind(this);

  uploadAnalysis = uploadAnalysis.bind(this);

  uploadSync = uploadSync.bind(this);

  login = login.bind(this);

  changePassword = changePassword.bind(this);

  constructor(
    baseUrl = 'https://ws.dezrann.net/',
    authUrl: string | null = null,
    changePwdUrl: string | null = null,
    isDirectory = false,
    staticRoute = '',
    user: User | null = null,
  ) {
    this.baseUrl = baseUrl;
    this.authUrl = authUrl;
    this.changePwdUrl = changePwdUrl;
    this.isDirectory = isDirectory;
    this.staticRoute = staticRoute;
    this.user = user;
  }
}
