// The vue internationalization initialization
// Removed support for the locales folder
// This app uses only single page component <i18n> blocks
// To add back support for the locales folder, run `vue add i18n`

// import Vue from 'vue';
// import VueI18n from 'vue-i18n';

// Vue.use(VueI18n);

// export default new VueI18n({
//   locale: process.env.VUE_APP_I18N_LOCALE || 'en',
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
// });

import Vue from 'vue';
import type { LocaleMessages } from 'vue-i18n';
import VueI18n from 'vue-i18n';
import { marked } from 'marked';

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const md = require.context('!!raw-loader!../locales', true, /[A-Za-z0-9-_,\s]+\.md$/i);

  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = key.split('/')[1];
      messages[locale] = { ...messages[locale], ...locales(key) };
    }
  });

  md.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = key.split('/')[1];
      const splittedKey = key.split('/');
      const fileName = splittedKey[splittedKey.length - 1].split('.')[0];

      if (fileName !== 'README') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newMessage: any = {};
        newMessage[fileName] = marked.parse(md(key).default);

        messages[locale] = { ...messages[locale], ...newMessage };
      }
    }
  });

  return messages;
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
});
