import type { Module } from 'vuex';
import type { SynchroStoreState } from './synchro.state';
import { state } from './synchro.state';
import { mutations } from './synchro.mutations';
import { actions } from './synchro.actions';

export const synchroModule: Module<SynchroStoreState, SynchroStoreState> = {
  state,
  mutations,
  actions,
  namespaced: true,
};
