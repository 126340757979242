/* eslint-disable @typescript-eslint/ban-ts-comment */
import type VueI18n from 'vue-i18n';
import type { PieceSettings } from '@/formats/PieceParser';
import type Audio from './Audio';
import type Signature from './Signature';
import type Source from './Source';

/**
 * A locale in short form
 *
 * eg. 'fr'
 */
export type Locale = string;

export enum Editor {
  CreationBar,
  EditorBar,
}

/**
 * A dezrann music piece
 *
 * Contains all information necessary to display a piece
 *
 * Source array may be empty if created using preloadPiece
 */
export default class Piece {
  readonly id: string;

  readonly path: string;

  readonly isLocal: boolean;

  readonly title: string;

  readonly localizedTitles: Record<Locale, string>;

  readonly composer: string;

  readonly openWithCreationBar: boolean;

  readonly signature: Signature;

  // readonly lastOffset: Onset;

  readonly sources: Source[];

  readonly tracks: Audio[];

  readonly availableTracks: number;

  readonly availableSources: number;

  readonly availableScores: number;

  readonly availableWaves: number;

  readonly curatedAnalyses: number;

  // TODO: create a type for Piece.opus
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly opus: Record<string, any>;

  readonly refs: Record<string, string>;

  readonly defaultAnalysis: string | null;

  readonly audioQuality: number;

  readonly musicalQuality: number;

  readonly settings: PieceSettings;

  readonly analysisGrid: string | null;

  static compareOpus(a: Partial<Piece>, b: Partial<Piece>): number {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of ['order', 'opus', 'movement:num', 'title']) {
    // for (const key of ['title', 'movement:num', 'opus', 'order']) {
      if (a.opus && b.opus) {
        const comparison = Piece
          .compareKey(a.opus[key], b.opus[key]);
        if (comparison !== 0) return comparison;
      }
      if (key === 'title' && (a.title && b.title)) {
        const comparison = Piece
          .compareKey(a.title, b.title);
        if (comparison !== 0) return comparison;
      }
    }
    return 0;
  }

  private static compareKey(a: string, b: string): number {
    if (a) {
      if (b) {
        const r = Piece.compareStrAndNbr(a, b);
        if (r !== 0) return r;
      } else return -1;
    } else if (b && !a) {
      return 1;
    }
    return 0;
  }

  private static compareStrAndNbr(a: string, b: string): number {
    const n1 = parseFloat(a);
    const n2 = parseFloat(b);
    if (!Number.isNaN(n1) && !Number.isNaN(n2)) {
      // eslint-disable-next-line no-nested-ternary
      return n1 < n2 ? -1 : (n1 > n2 ? 1 : 0);
    }
    // eslint-disable-next-line no-nested-ternary
    return a < b ? -1 : (a > b ? 1 : 0);
  }

  readonly permissions: Array<string> | null;

  localize(locale: string, key: string): string {
    const locale0 = locale.split('-')[0];

    return this.opus[`${key}:${locale}`] || this.opus[`${key}:${locale0}`] || this.opus[key];
  }

  localizeContributor(locale: string, key: string): string {
    const contributor = this.opus.contributors[`${key}:${locale}`] || this.opus.contributors[key];

    // Normalize, transforming "Bar, Foo" in "Foo Bar"
    const i = contributor.indexOf(',');

    if (i === -1) return contributor;
    return `${contributor.slice(i + 1)} ${contributor.slice(0, i)}`;
  }

  removeFlatForGermanNotation(key: string): string {
    return key
      .replace('flat', '')
      .replace('b ', ' ')
      .replace('b', '')
      .replace('♭ ', ' ')
      .replace('♭ ', ' ')
      .replace('♭', '');
  }

  getKey(i18n: VueI18n): string {
    if (!this.opus.key) { return ''; }

    let { key } = this.opus;

    if (i18n.t('key.Bflat').toString().length > 0 && ['Bflat', 'Bb', 'B b', 'B flat', 'B♭', 'B ♭'].some((comp) => key.includes(comp))) {
      key = i18n.t('key.Bflat') + key.substring(1);
      key = this.removeFlatForGermanNotation(key);
    } else {
      key = i18n.t(`key.${key[0]}`) + key.substring(1);
    }

    key = key
      .replace('Major', i18n.t('key.major'))
      .replace('major', i18n.t('key.major'));
    key = key
      .replace('minor', i18n.t('key.minor'));
    // Homogeneize alterations
    key = key
      .replace('sharp', '♯')
      .replace('#', '♯')
      .replace('flat', '♭')
      .replace('b ', '♭ ')
      .replace(' ♭', '♭')
      .replace(' ♯', '♯');

    return key;
  }

  getSortKey(): string {
    // Pieces without opus info are at the end
    return (this.opus.contributors || 'zz') + this.id;
  }

  getTitle(locale: string): string {
    if (this.opus['movement:numtitle']) {
      return this.localize(locale, 'movement:numtitle');
    }

    if (this.opus['movement:num'] && this.opus['movement:title']) {
      return (`${this.opus['movement:num']}. ${this.localize(locale, 'movement:title')}`);
    }

    if (this.opus['movement:title']) {
      return this.localize(locale, 'movement:title');
    }

    if (this.opus['variant:first']) {
      return this.localize(locale, 'variant:first');
    }

    // In all the previous cases, should have piece:title as well

    if (this.opus['piece:title']) {
      return this.localize(locale, 'piece:title');
    }

    if (this.opus.title) {
      return this.localize(locale, 'title');
    }

    return this.localizedTitles[locale] || this.title;
  }

  getPieceTitle(locale: string): string {
    const details = [];

    if (this.opus['piece:title']) {
      details.push(this.localize(locale, 'piece:title'));
    }
    return details.join(', ');
  }

  getPieceAndTitle(locale: string): string {
    // A bit more complete title
    let piecetitle = this.getPieceTitle(locale);
    if (piecetitle) { piecetitle += ', '; }

    piecetitle += this.getTitle(locale);

    return piecetitle;
  }

  getContributors(locale: string): string {
    // Mainly the composer or artist
    const details = [];
    if (this.opus.contributors && this.opus.contributors.artist) {
      details.push(this.localizeContributor(locale, 'artist'));
    }

    if (this.opus.contributors && this.opus.contributors.composer) {
      details.push(this.localizeContributor(locale, 'composer'));
    } else if (this.composer) {
      // Back-compatibility
      details.push(this.composer);
    }

    if (this.opus.contributors && this.opus.contributors.lyricist) {
      details.push(this.localizeContributor(locale, 'lyricist'));
    }

    if (this.opus.contributors && this.opus.contributors.arranger) {
      details.push(this.localizeContributor(locale, 'arranger'));
    }

    if (this.opus.contributors && this.opus.contributors.performer) {
      details.push(this.localizeContributor(locale, 'performer'));
    }

    if (this.opus.contributors && this.opus.contributors.singer) {
      details.push(this.localizeContributor(locale, 'singer'));
    }

    const detailsUnique = details.filter((value, index, array) => array.indexOf(value) === index);

    return detailsUnique.join(', ');
  }

  getMetadataOne(locale: string): string {
    // Metadata for TitleManager, first line, now only the contributors

    return this.getContributors(locale);
  }

  getMetadataCollection(locale: string): string {
    const details = [];

    if (this.opus.collection) {
      details.push(this.localize(locale, 'collection'));
    }

    return details.join(', ');
  }

  getMetadataTwo(locale: string, opusYear = true): string {
    // Metadata for TitleManager, second line, piece or collection, and opus/year
    const details = [];

    if (this.opus['piece:title']) { // Should have movement:(num)title as well
      details.push(this.localize(locale, 'piece:title'));
    } else if (this.opus.collection) { // May be redundant with piece:title
      details.push(this.localize(locale, 'collection'));
    }

    /*  // Often redundant to either title or collection
    {
      if (this.opus.genre) {
        details.push(this.opus.genre);
      }
    }
    */

    if (opusYear && this.opus.opus) {
      details.push(this.opus.opus);
    }

    let d = details.join(', ');

    if (opusYear && this.opus.year) {
      d += ` (${this.opus.year})`;
    }

    return d;
  }

  getMetadataCorpus(locale: string): string {
    // Metadata, third line, corpus name
    const details = [];

    if (this.opus.corpus) {
      details.push(this.localize(locale, 'corpus'));
    }

    return details.join(', ');
  }

  getContents(i18n: VueI18n): string {
    const details = [];

    if (this.signature.pretty) {
      details.push(this.signature.pretty);
    }
    if (this.opus.key) {
      details.push(this.getKey(i18n));
    }

    const src = [];
    // if (this.availableTracks > 1) {
    //   src.push(`${this.availableTracks}•`);
    // }
    // if (this.availableSources > 1) {
    //   src.push(`${this.availableSources}‣`);
    // }
    // if (this.openWithCreationBar) {
    //  src.push('S');
    // }
    if (this.isLocal) {
      src.push('L');
    }
    if (src.length) {
      details.push(src.join(' '));
    }

    return details.join(', ');
  }

  can(verb: string): boolean {
    if (this.permissions) {
      return this.permissions.includes(verb) || this.permissions.includes('all');
    }
    return false;
  }

  protected constructor(base: Omit<Piece, 'getTitle' | 'getMetadataCollection' | 'getPieceTitle' | 'getPieceAndTitle' | 'getContributors' | 'getMetadataCorpus' | 'getMetadataOne' | 'getMetadataTwo' | 'LOCALIZED_TONALITY' | 'getKey' | 'getContents' | 'getSortKey' | 'localize' | 'localizeContributor' | 'compareOpus' | 'removeFlatForGermanNotation' | 'can' >) {
    this.id = base.id;
    this.path = base.path;
    this.isLocal = base.isLocal;
    this.title = base.title;
    this.localizedTitles = base.localizedTitles;
    this.composer = base.composer;
    this.openWithCreationBar = base.openWithCreationBar;
    this.signature = base.signature;
    this.sources = base.sources;
    this.tracks = base.tracks;
    this.curatedAnalyses = base.curatedAnalyses;
    this.availableSources = base.availableSources;
    this.availableTracks = base.availableTracks;
    this.availableScores = base.availableScores;
    this.availableWaves = base.availableWaves;
    this.opus = base.opus;
    this.refs = base.refs;
    this.defaultAnalysis = base.defaultAnalysis;
    this.audioQuality = base.audioQuality;
    this.musicalQuality = base.musicalQuality;
    this.settings = base.settings;
    this.permissions = base.permissions;
    this.analysisGrid = base.analysisGrid;
    // this.lastOffset = base.lastOffset;
  }
}
