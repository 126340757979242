import FilterGroup from './FilterGroup';
import type { LabelId, LabelNoId, LabelTemplate } from './Label';
import type Label from './Label';

// The id of the first label added to the analysis
// Allows for easier debugging, and garanteed truthiness of valid ids
const START_ID = 100;

/**
 * The user-generated analysis of a piece
 *
 * May be created by the user or parsed from a dez file
 *
 * Allow custom display rules using filter groups
 */
export default class Analysis {
  fileName: string | null;

  readonly labels: Record<LabelId, Label>;

  templates: LabelTemplate[];

  lastId: LabelId;

  dirty = false;

  readonly meta: Readonly<Record<string, string>>;

  readonly filters: FilterGroup;

  readonly layoutRaw: unknown;

  constructor(
    labels: LabelNoId[],
    templates: LabelTemplate[] = [],
    meta: Record<string, string> = {},
    filters: FilterGroup = new FilterGroup([]),
    layout: unknown[] = [],
    fileName: string | null = null,
  ) {
    this.labels = {};

    const labelsArray = labels.map((labelNoId, index) => (
      { ...labelNoId, id: index + START_ID }
    ));

    labelsArray.forEach((l) => { this.labels[l.id] = l; });

    this.templates = [...templates];

    this.lastId = START_ID + labelsArray.length;
    this.meta = meta || {};
    this.filters = filters;
    this.layoutRaw = layout;
    this.fileName = fileName;
  }
}
