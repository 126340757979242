type Locale = string;

export default class CorpusData {
  readonly id: string;

  readonly path: string;

  readonly shorttitle: string;

  readonly localizedShortTitles: Record<Locale, string>;

  readonly title: string;

  readonly localizedTitles: Record<Locale, string>;

  readonly contributors: Record<string, unknown>;

  readonly year: string;

  readonly genre: string;

  readonly text: string;

  readonly localizedTexts: Record<Locale, string>;

  readonly availability: string;

  readonly localizedAvailabilities: Record<Locale, string>;

  readonly image: string;

  readonly imageCard: string;

  readonly showcase: string;

  readonly opus: string;

  readonly motto: string;

  readonly localizedMottos: Record<Locale, string>;

  readonly refs: Record<string, string>;

  readonly columns: string;

  public showcaseName = '';

  setLocalizedShowcaseName(localizedShowcaseName: string): void {
    this.showcaseName = localizedShowcaseName;
  }

  getTitle(fullLocale: string): string {
    const locale = fullLocale.split('-')[0];
    return this.localizedTitles[locale] || this.title;
  }

  getShortTitle(fullLocale: string): string {
    const locale = fullLocale.split('-')[0];
    return this.localizedShortTitles[locale] || this.shorttitle || this.getTitle(fullLocale);
  }

  getText(fullLocale: string): string {
    const locale = fullLocale.split('-')[0];
    return this.localizedTexts[locale] || this.text;
  }

  getAvailability(fullLocale: string): string {
    const locale = fullLocale.split('-')[0];
    return this.localizedAvailabilities[locale] || this.availability;
  }

  getMetadata(fullLocale: string): string {
    const locale = fullLocale.split('-')[0];

    const details = [];

    if (this.contributors && this.contributors.composer) {
      const composer = this.contributors[`composer:${locale}`] || this.contributors.composer;

      details.push(composer);
    }

    if (this.opus) {
      details.push(this.opus);
    }

    const d = details.join(', ');

    // if (this.year) {
    //  d += ` (${this.year})`;
    // }

    return d;
  }

  getMotto(fullLocale: string): string {
    const locale = fullLocale.split('-')[0];
    return this.localizedMottos[locale] || this.motto;
  }

  protected constructor(base: Omit<CorpusData, 'getTitle' | 'getMetadata' | 'getShortTitle' | 'getText' | 'getAvailability' | 'getMotto' | 'getRandomShowcase' | 'setLocalizedShowcaseName' | 'getLocalizedShowcaseName' | 'showcaseName'>) {
    this.id = base.id;
    this.path = base.path;
    this.shorttitle = base.shorttitle;
    this.localizedShortTitles = base.localizedShortTitles;
    this.title = base.title;
    this.localizedTitles = base.localizedTitles;
    this.contributors = base.contributors;
    this.year = base.year;
    this.genre = base.genre;
    this.text = base.text;
    this.localizedTexts = base.localizedTexts;
    this.availability = base.availability;
    this.localizedAvailabilities = base.localizedAvailabilities;
    this.image = base.image;
    this.imageCard = base.imageCard;
    this.showcase = base.showcase;
    this.opus = base.opus;
    this.motto = base.motto;
    this.refs = base.refs;
    this.localizedMottos = base.localizedMottos;
    this.columns = base.columns;
  }
}
