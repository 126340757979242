import type { Second } from './Unit';
import type Unit from './Unit';

type AudioData = {
  secondUnit: Unit<Second>;
  /** The url to the audio file, if any */
  url?: string;
  /** The youtube id of the associated video, if any */
  youtubeId?: string;
  /** additional info */
  info: string;
};

export default class Audio implements Readonly<AudioData> {
  readonly secondUnit: Unit<Second>;

  readonly url?: string;

  readonly youtubeId?: string;

  readonly info: string;

  protected constructor(parsed: AudioData) {
    this.secondUnit = parsed.secondUnit;
    this.url = parsed.url;
    this.youtubeId = parsed.youtubeId;
    this.info = parsed.info;
  }
}
