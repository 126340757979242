import { Signature } from '@/data';
import type { TMeasureMap } from '@/data/MeasureMap';

export type SignatureFormat = `${number}/${number}` | TMeasureMap;

export default class SignatureParser extends Signature {
  /**
   * Creates a time signature from non-sanitized input
   * @param ts a string in the format 'X/Y'
   * @param upbeat a float string or number
   * @returns a time signature, defaulted to sensible values in case of missing data
   */
  static fromFormat(ts?: SignatureFormat, upbeat?: number | string): Signature {
    if (Array.isArray(ts)) {
      return new Signature(ts);
    }

    // Get members, or an empty array
    const members = String(ts).split('/');
    // If member does not exist, or parsing fails, or parsed value is 0,
    // use 0/4, it will be handled as a default 4/4
    const num = Number.parseInt(members[0], 10) || 0;
    const denum = Number.parseInt(members[1], 10) || 4;
    // If upbeat is not a string, convert it to one
    const upbeatString = String(upbeat) || '0';
    // If converted string parses to a float, use it as such
    // Otherwise use 0
    const upbeatChecked = Number.parseFloat(upbeatString) || 0.0;

    return new Signature({
      numerator: num,
      denominator: denum,
      upbeat: upbeatChecked,
    });
  }
}
