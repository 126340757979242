import type { ActionTree } from 'vuex';
import type { Piece } from '@/data';
import type { DefaultStoreState } from './default.state';

export const actions: ActionTree<DefaultStoreState, DefaultStoreState> = {
  updateColors({ commit }, payload: { primary: string, secondary: string }) {
    commit('updatePrimaryColor', payload.primary);
    commit('updateSecondaryColor', payload.secondary);
  },
  updateCorpus({ commit }, payload: {
    corpus: Piece[],
    corpusId: string,
  }) {
    commit('updateCorpus', payload.corpus);
    commit('updateCorpusId', payload.corpusId);
  },
  updateIndexCurrentPiece({ commit }, payload: {
    indexCurrentPiece: number,
  }) {
    commit('updateIndexCurrentPiece', payload.indexCurrentPiece);
  },
  updateAllowedToPlay({ commit }, payload: {
    allowedToPlay: boolean,
  }) {
    commit('updateAllowedToPlay', payload.allowedToPlay);
  },
  updateDebugMode({ commit }, debug: boolean) {
    commit('updateDebugMode', debug);
  },
  updateLockEditor({ commit }, lock: boolean) {
    commit('updateLockEditor', lock);
  },
  hideSnack({ commit }) {
    commit('hideSnack');
  },
  showSnack({ commit }) {
    commit('showSnack');
  },
  updateSnackText({ commit }, text: string) {
    commit('updateSnackText', text);
  },
};
