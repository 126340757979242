import type { PieceFormat } from '@/formats/PieceParser';
import PieceParser from '@/formats/PieceParser';
import type { Piece } from '@/data';
import type { CorpusDataFormat } from '@/formats/CorpusParser';
import CorpusParser from '@/formats/CorpusParser';
import type CorpusData from '@/data/CorpusData';
import type { Response } from './ClientResponse';
import type { IClient } from './IClient';
import { loadPiece } from './piece';

export interface CorpusFormat {
  id: string;
  pieces?: PieceFormat[];
  corpora?: CorpusFormat[];
}

export interface Corpus {
  pieces: Piece[];
}

function flattenCorpus(corpus: CorpusFormat, path = ''): Piece[] {
  const pieces = (corpus.pieces ?? [])
    .map((p) => PieceParser.fromFormat(path + p.id, false, p, []));

  const childPieces = (corpus.corpora ?? [])
    .map((c) => flattenCorpus(c, `${path}${c.id}/`)).flat();

  return [...pieces, ...childPieces];
}

export async function loadCorpus(this: IClient, forceRefresh = false): Promise<Response<Corpus>> {
  // Local corpus loading
  if (this.isDirectory) {
    const corpusUrl = this.baseUrl;
    const pieceNames = (await this.getFolder(corpusUrl));

    if (pieceNames.isErr()) return pieceNames.cast();

    // Load all local piece, but not their sources
    // Using bind because loadPiece is not included in the Client type yet
    const piecePromises = pieceNames.data.map((name) => loadPiece.bind(this)(name, false));

    const piecesAll = await Promise.allSettled(piecePromises);

    const pieces = piecesAll
      .filter((pRes) => pRes.status === 'fulfilled')
      .map((pRes) => (pRes as PromiseFulfilledResult<Response<Piece>>).value.data);

    const failedPieces = piecesAll
      .filter((pRes) => pRes.status === 'rejected')
      .map((pRes) => (pRes as PromiseRejectedResult).reason);

    // eslint-disable-next-line no-console
    if (failedPieces.length) console.warn('Corpus load failed for: ', failedPieces);
    return this.tryParse(() => ({ pieces }));
  }

  // Recursive full corpus loading
  const corpusRecursive = localStorage.getItem('dezrann-recursive-corpus');
  if (corpusRecursive && !forceRefresh) {
    const pieces = JSON.parse(corpusRecursive);
    if (new Date() > pieces.expiry) {
      localStorage.removeItem('dezrann-recursive-corpus');
    } else {
      return this.tryParse(() => ({ pieces: flattenCorpus(pieces) }));
    }
  }

  const corpusUrl = `${this.baseUrl}corpus/recursive`;
  const format = await this.get<CorpusFormat>(corpusUrl);
  if (format.isErr()) return format.cast();

  localStorage.setItem('dezrann-recursive-corpus', JSON.stringify({ ...format.data, expiry: Date.now() + 3 * 60 * 1000 }));
  return this.tryParse(() => ({ pieces: flattenCorpus(format.data) }));
}

export async function loadShowcaseCorpus(this: IClient): Promise<Array<CorpusData>> {
  const corpusUrl = `${this.baseUrl}corpus/recursive?flatCorpus=true`;

  const { data } = await this.get<Array<CorpusDataFormat>>(corpusUrl);

  const corpora = data.map((item) => CorpusParser.fromFormat(item));

  return corpora;
}
