import type { Audio, Source } from '@/data';
import { Piece } from '@/data';
import type { TMeasureMap } from '@/data/MeasureMap';
import type { AudioFileFormat } from './AudioParser';
import type { SignatureFormat } from './SignatureParser';
import SignatureParser from './SignatureParser';
import type { Image3DFileFormat, ImageFileFormat } from './SourceParser';

/**
 * A locale in short form
 *
 * eg. 'fr'
 */
 type Locale = string;

 /**
  * A key to a localized title in a piece
  */
 type PieceTitleKey = `title:${Locale}`;

 /**
  * A map of localized titles in a pice
  */
 type PieceTitleMap = Record<PieceTitleKey, string>;

/**
  * All the displayable sources for the piece
  * May include scores, audio files and waves
  */
interface SourcesFormat {
  images?: Array<ImageFileFormat | Image3DFileFormat>;
  audios?: AudioFileFormat[];
}

export interface PieceSettings {
  'score-minimap': boolean;
  'panels'?: Array<Array<number>>;
}

/**
  * A parsed .dezrann file
  * Contains all relevant info to load and display the piece
  */
export interface PieceFormat extends PieceTitleMap {
  'id': string;
  'title': string;
  'composer'?: string;
  'editor'?: 'dez-edit-bar';
  'time-signature'?: SignatureFormat;
  'time-signature-upbeat'?: number;
  'last-offset'?: number;

  'opus': Record<string, string>;
  'sources': SourcesFormat;
  'analysis:default'?: string;
  'quality:audio'?: number;
  'quality:audio:synchro'?: number;
  'quality:musical-time'?: number;

  'analysis:grid'?: string;

  settings?: PieceSettings;
  'permissions'?: Array<string>;
}

function checkString(val: unknown): string {
  if (typeof val === 'number') return val.toString();
  if (typeof val !== 'string') {
    throw new Error(`Corrupted Piece: ${val} is not a string`);
  }
  return val;
}

export default class PieceParser extends Piece {
  static fromFormat(
    path: string,
    isLocal: boolean,
    format: PieceFormat,
    sources: Source[] = [],
    tracks: Audio[] = [],
  ): Piece {
    const id = checkString(format.id);
    const opus = format.opus || {};

    const title = checkString(format.title || '');
    // const lastOffset = Number.parseFloat(format['last-offset']?.toString()) || ;

    const signature = SignatureParser.fromFormat(
      (format.opus && format.opus['measure-map']) ? format.opus['measure-map'] as unknown as TMeasureMap : format['time-signature'] || '' as SignatureFormat | TMeasureMap,
      format['time-signature-upbeat'] || 0,
    );

    const composer = checkString(format.composer || '');

    const localizedTitles: Record<Locale, string> = {};

    Object.keys(format).forEach((k) => {
      const splitKey = k.split(':');
      if (splitKey.length === 2 && splitKey[0] === 'title') {
        const localeTitle = checkString((format as unknown as Record<string, string>)[k]);

        localizedTitles[splitKey[1]] = localeTitle;
      }
    });

    const refs: Record<string, string> = {};

    Object.keys(opus).forEach((k) => {
      if (k.substring(0, 3) === 'ref') {
        refs[k.substring(4)] = checkString((opus as unknown as Record<string, string>)[k]);
      }
    });

    const openWithCreationBar = format.editor === 'dez-edit-bar';
    const availableSources = (format.sources?.images?.length ?? 0)
      + (format.sources?.audios?.map((a) => a.images?.length ?? 0) ?? [])
        .reduce((a, b) => a + b, 0);
    const availableTracks = format.sources?.audios?.length ?? 0;

    const availableScores = format.sources?.images?.length ?? 0;
    const availableWaves = availableSources - availableScores;

    const defaultAnalysis = checkString(format['analysis:default'] || '') || null;
    const curatedAnalyses = defaultAnalysis ? 1 : 0;

    const audioQuality = (format['quality:audio:synchro'] ?? 3) < 3
      ? 0
      : format['quality:audio'] ?? 0;
    const musicalQuality = format['quality:musical-time'] ?? 3;

    const settings: PieceSettings = {
      'score-minimap': format?.settings?.['score-minimap'] === undefined ? true : format.settings?.['score-minimap'],
      panels: format?.settings?.panels,
    };
    const permissions = format.permissions || null;

    const analysisGrid = format['analysis:grid'] || null;

    return new Piece({
      id,
      path,
      isLocal,
      title,
      sources,
      tracks,
      availableSources,
      availableTracks,
      signature,
      openWithCreationBar,
      // lastOffset,
      composer,
      localizedTitles,
      availableScores,
      availableWaves,
      curatedAnalyses,
      opus,
      refs,
      defaultAnalysis,
      audioQuality,
      musicalQuality,
      settings,
      permissions,
      analysisGrid,
    });
  }
}
