import type { StaffId } from './Staff';

// check sort
// Actually parse items
// Generate type style (opacity, text)

/**
 * Describes a Label type, used to initialize the default FilterGroup
 * Slightly different from FilterArg for smaller DefaultTypes size
 */
export interface TypeArg {
  /**
   * The name of the type, as displayed in the interface and present in .dez files
   */
  readonly name: string;
  /**
   * If the types should be shown as an option to the user
   */
  readonly isLegacy?: boolean;
  /**
   * If no line is present in the dez file, use this one (should not be saved after)
   */
  readonly line?: StaffId;
  /**
   * The color of the label and associated UI elements
   * An 'auto' color means the color will be assigned automatically
   */
  readonly color?: string;
  /**
   * The weight of the label text
   */
  readonly fontWeight?: string;
  /**
   * The style of the label text
   */
  readonly fontStyle?: string;
  /**
   * The size of the label text
   */
  readonly fontSize?: string;
}

/**
 * Describes a filter to pass to a FilterGroup
 *
 * More information is then added by the FilterGroup
 */
export interface FilterArg {
  readonly tag: string | null;

  readonly type: string | null;

  readonly layers: string[];

  readonly line: StaffId | null;

  readonly color: string | null;

  readonly fontWeight: string | null;

  readonly fontStyle: string | null;

  readonly fontSize: string | null;
}

/**
 * Describes a filter, or set of display rules
 *
 * Affects the css properties and default position of labels that match the type and layers
 */
export interface Filter extends FilterArg {
  /**
   * If the type and layers should be considered valid new values from the user
   */
  readonly displayable: boolean;
}

/**
 * Describes a group of filters
 *
 * Can match labels to rules
 *
 * Can generate css rules
 */
export default class FilterGroup {
  readonly filters: Filter[];

  constructor(filters: Filter[]) {
    this.filters = [...filters];
    // Sort filters by the number of rules defined, in descending order
    this.filters.sort((t1, t2) => (
      ((t1.type ? 1 : 0) + t1.layers.length)
      - ((t2.type ? 1 : 0) + t2.layers.length)));
  }

  /**
   * @returns a set of all unique types in the filters
   */
  getTypeNames(): Set<string> {
    return new Set(this.filters
      .filter((f) => f.displayable && f.type)
      .map((f) => f.type as string));
  }

  /**
   * @returns a set of all unique types in the layers
   */
  getLayersNames(): Set<string> {
    return new Set(this.filters
      .filter((f) => f.displayable && f.type)
      .map((f) => f.type as string));
  }

  /**
   * @param type the type of a label
   * @param layers the layers of a label
   * @returns the default line of the label if defined in the filters
   */
  getDefaultLine(type: string, tag: string, layers: string[]): StaffId | null {
    const layerSet = new Set(layers);

    // Finds
    const filter = this.filters.find((f) => !!f.line
      && (!f.type || f.type === type)
      && (!f.tag || f.tag === tag)
      && f.layers.every((l) => layerSet.has(l)));

    return filter?.line ?? null;
  }

  /**
   * Generate a filter group from a list of types
   */
  static fromTypes(types: TypeArg[]): FilterGroup {
    const filters: Filter[] = types.map((filter) => ({
      layers: [],
      color: filter.color || null,
      fontWeight: filter.fontWeight || null,
      fontSize: filter.fontSize || null,
      fontStyle: filter.fontStyle || null,
      type: filter.name,
      tag: null,
      line: filter.line || null,
      displayable: !filter.isLegacy,
    }));
    return new FilterGroup(filters);
  }

  /**
   * Generate a filter group from a list of user defined filters
   */
  static fromFilters(filterArgs: FilterArg[]): FilterGroup {
    const filters: Filter[] = filterArgs.map((filter) => ({
      layers: [...filter.layers || []].sort(),
      type: filter.type || null,
      tag: filter.tag || null,
      color: filter.color || null,
      fontWeight: filter.fontWeight,
      fontSize: filter.fontSize,
      fontStyle: filter.fontStyle,
      line: filter.line || null,
      displayable: true,
    }));
    return new FilterGroup(filters);
  }
}
