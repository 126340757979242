import type { Piece } from '@/data';
import PieceParser from '@/formats/PieceParser';

export interface DefaultStoreState {
  primaryColor: string;
  secondaryColor: string;
  corpus: Piece[];
  corpusId: string | null;
  indexCurrentPiece: number;
  allowedToPlay: boolean;
  debugMode: boolean;
  lockEditor: boolean;
  snack: {
    show: boolean;
    text: string;
  }
}

export const state = (): DefaultStoreState => ({
  primaryColor: 'primary',
  secondaryColor: 'secondary',
  corpus: [PieceParser.fromFormat('', false, {
    id: '',
    title: '...',
    opus: {},
    sources: {},
    permissions: [],
  })],
  corpusId: null,
  indexCurrentPiece: 0,
  allowedToPlay: false,
  debugMode: false,
  lockEditor: false,
  snack: {
    show: false,
    text: '',
  },
});
