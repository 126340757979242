import type CorpusData from '@/data/CorpusData';
import type { CorpusDataFormat } from '@/formats/CorpusParser';
import CorpusParser from '@/formats/CorpusParser';
import type { Response } from './ClientResponse';
import { ClientResponse } from './ClientResponse';
import type { IClient } from './IClient';

export async function loadCorpusData(
  this: IClient,
  path: string,
): Promise<Response<CorpusData>> {
  try {
    const corpusUrl = `${this.baseUrl}corpus/${path}/recursive?flatCorpus=true`;

    const format = (await this.get<Array<CorpusDataFormat>>(corpusUrl)).data;
    if (format !== null) {
      return this.tryParse(() => CorpusParser.fromFormat(format[0] as CorpusDataFormat));
    }
    throw new Error('not found');
  } catch (e) {
    if (ClientResponse.isClientError(e)) return e.cast();
    return ClientResponse.fromParsingError(e as Error);
  }
}
