import { Client } from '@/client';

const distClient = new Client(
  process.env.VUE_APP_CORPUS_URL,
  process.env.VUE_APP_AUTH_URL,
  process.env.VUE_APP_CHPWD_URL,
  false,
  'static/resources',
);

const localClient = new Client(
  `${process.env.BASE_URL}local-corpus/`,
  null,
  null,
  true,
);

const clients = [distClient, localClient];

export { distClient, localClient, clients };
