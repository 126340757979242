import type { Pixel } from './Unit';

export type StaffId = number;

export const lineIds = {
  all: 0 as StaffId,
  top1: 1 as StaffId,
  top2: 2 as StaffId,
  top3: 3 as StaffId,
  bot1: 4 as StaffId,
  bot2: 5 as StaffId,
  bot3: 6 as StaffId,
} as const;

export const STAFF_ID_OFFSET = 7; // Number of default staves

export enum StaffType {
  staff,
  line,
  bar,
}

export default class Staff {
  readonly id: StaffId;

  readonly top: Pixel;

  readonly bottom: Pixel;

  readonly height: Pixel;

  readonly name: string;

  readonly type: StaffType;

  protected constructor(
    id: StaffId,
    top: Pixel,
    bottom: Pixel,
    name: string,
    type = StaffType.staff,
  ) {
    this.id = id;
    this.top = top;
    this.bottom = bottom;
    this.height = (this.bottom - this.top) as Pixel;
    this.name = name;
    this.type = type;
  }
}
