import type { Pixel } from '@/data';
import {
  Staff, StaffType, STAFF_ID_OFFSET,
} from '@/data';
import { LineName } from './analysis-formats';
import type { StaffFormat } from './UnitParser';

// 34 is ~ 102 / 3, the line margin of the piece divided by the number of lines
const LINE_HEIGHT = 34;

const TOTAL_MARGIN = 0 + LINE_HEIGHT * 3;

/**
 * Placeholder generator for lines and staves from a format
 *
 * Might not fit source exactly
 */
export default class StaffParser extends Staff {
  private static getLine(id: number, name: LineName, top: number, height = LINE_HEIGHT): Staff {
    return new Staff(
      id,
      top as Pixel,
      (top + height) as Pixel,
      name,
      id ? StaffType.line : StaffType.bar,
    );
  }

  static parseFormat(formats: StaffFormat[], canvasHeight: Pixel): Staff[] {
    // Create the bottom lines
    const bottomLineOffset = TOTAL_MARGIN + canvasHeight;

    const lines = [
      this.getLine(0, LineName.all, 0, canvasHeight + LINE_HEIGHT * 6),
      this.getLine(1, LineName.top1, LINE_HEIGHT * 0),
      this.getLine(2, LineName.top2, LINE_HEIGHT * 1),
      this.getLine(3, LineName.top3, LINE_HEIGHT * 2),
      this.getLine(4, LineName.bot1, LINE_HEIGHT * 0 + bottomLineOffset),
      this.getLine(5, LineName.bot2, LINE_HEIGHT * 1 + bottomLineOffset),
      this.getLine(6, LineName.bot3, LINE_HEIGHT * 2 + bottomLineOffset),
    ];

    const idOffset = STAFF_ID_OFFSET;

    // Load the staves
    const staves = formats.map((format, index) => {
      const top = format.top + TOTAL_MARGIN;
      const bottom = format.bottom + TOTAL_MARGIN;
      const name = (index + 1).toString();

      return new Staff(index + idOffset, top as Pixel, bottom as Pixel, name);
    });

    return [...lines, ...staves];
  }
}
