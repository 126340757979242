import type { AnalysisFormat } from '@/formats/analysis-formats';
import AnalysisParser from '@/formats/AnalysisParser';
import type { Analysis, Piece } from '@/data';
import type { Response } from './ClientResponse';
import { ClientResponse } from './ClientResponse';

import type { IClient } from './IClient';

export async function loadAnalysis(
  this: IClient,
  piece: Piece,
  analysisId: string,
): Promise<Response<Analysis>> {
  let url;
  if (this.isDirectory) {
    url = this.url(piece.path, 'analyses', analysisId);
  } else {
    url = this.url('corpus', piece.path, 'analyses', analysisId);
  }
  if (!url.isOk()) return url.cast();
  const response = await this.get<{content: AnalysisFormat}>(url.data);

  if (this.isDirectory) {
    return this.tryParse(
      () => AnalysisParser.parseFormat(response.data as unknown as AnalysisFormat, analysisId),
    );
  }

  const content = typeof response.data.content === 'string' ? JSON.parse(response.data.content) : response.data.content;

  const analysisFormat: AnalysisFormat = {
    ...content,
    signature: piece.signature,
  };

  return this.tryParse(() => AnalysisParser.parseFormat(analysisFormat, analysisId));
}

export async function loadAnalysesList(this: IClient, id: string): Promise<Response<string[]>> {
  let url;
  if (this.isDirectory) {
    url = this.url(id, 'analyses');
  } else {
    url = this.url('corpus', id, 'analyses');
  }

  if (!url.isOk()) return url.cast();

  return this.getFolder(url.data);
}

export async function uploadAnalysis(
  this: IClient,
  piecePath: string,
  analysisId: string,
  analysis: Analysis,
): Promise<Response<unknown>> {
  let body;
  try {
    const format = AnalysisParser.exportFormat(analysis);
    const content = JSON.stringify(format);
    body = { name: analysisId, content };
  } catch (e) {
    return ClientResponse.fromParsingError(e as Error);
  }
  let url;
  if (this.isDirectory) {
    url = this.url(piecePath, 'analyses');
  } else {
    url = this.url('corpus', piecePath, 'analyses');
  }

  if (!url.isOk()) return url.cast();

  return this.post<unknown>(url.data, body);
}
