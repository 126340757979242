import type { AudioFileFormat } from '@/formats/AudioParser';
import AudioParser from '@/formats/AudioParser';
import type {
  AudioImageFileFormat, Image3DFileFormat, ImageDimensions, ImageFileFormat,
} from '@/formats/SourceParser';
import SourceParser from '@/formats/SourceParser';
import StaffParser from '@/formats/StaffParser';
import type {
  AudioImageSyncFormat, AudioSyncFormat, Image3DSyncFormat, ImageSyncFormat,
  UnitCoupleFormat,
} from '@/formats/UnitParser';
import UnitParser from '@/formats/UnitParser';
import type {
  Audio, Onset, Pixel, Second, Source, Staff, Unit,
} from '@/data';
import { StaffType, SourceType } from '@/data';
import type { PieceFormat } from '@/formats/PieceParser';
import type { Image3DPageData } from '@/data/Source';
import { ClientResponse } from './ClientResponse';
import type { IClient } from './IClient';

async function loadImageDimensions(url: string): Promise<ImageDimensions> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve({
      width: image.naturalWidth as Pixel,
      height: image.naturalHeight as Pixel,
    });
    image.onerror = reject;
    image.src = url;
  });
}

/**
 * Filter out an array of settles promises and log errors
 * @param result The output array of Promise.allSettled
 * @returns All successful results
 */
function filterSettled<T>(result: PromiseSettledResult<T>[]): T[] {
  return result.map((r) => {
    if (r.status === 'fulfilled') {
      return r.value;
    }
    // eslint-disable-next-line no-console
    console.error('Could not load source', r.reason);
    return null;
  }).filter((r) => !!r) as T[];
}

/**
 * Load an image source using info from the Piece object
 * @param this the dezrann client
 * @param format the format of the image info, from the SourceFormat
 * @param id the id of the piece
 * @returns a complete Source object
 */
async function loadImageSource(
  this: IClient,
  format: ImageFileFormat,
  id: string,
): Promise<Source> {
  const url = this.url(`${this.staticRoute}/${id}`, format.positions).data;

  const imageUrl = this.url(`${this.staticRoute}/${id}`, format.image).data;

  const syncFormat = (await this.get<ImageSyncFormat>(url)).data;

  const pixelUnit = UnitParser.createImageUnit(syncFormat);

  const dimensions = await loadImageDimensions(imageUrl);

  const staves = StaffParser.parseFormat(syncFormat.staffs, dimensions.height);

  return SourceParser
    .fromFormat(format, dimensions, pixelUnit, staves, imageUrl, undefined, [{
      height: dimensions.height,
      rows: [{
        staves,
        y: staves[0].top,
        height: staves[0].height,
        end: dimensions.width,
        start: 0 as Pixel,
      }],
    }]);
}

async function loadImage3DSource(
  this: IClient,
  format: Image3DFileFormat,
  id: string,
): Promise<Source> {
  const url = `${this.baseUrl}${this.staticRoute}/${id}/${format.positions}`;
  const imageUrl = this.url(id, format.image).data;
  const syncFormat = (await this.get<Image3DSyncFormat>(url)).data;
  const pixel3DUnit = UnitParser.createImage3DUnit(syncFormat);
  let resourcesUrl = '';
  if (this.isDirectory) {
    resourcesUrl = this.url(`${id}/`).data;
  } else if (syncFormat.pages[0].image.endsWith('.svg')) {
    resourcesUrl = `${this.baseUrl}resources/${id}/sources/images/score/`;
  } else {
    resourcesUrl = `${this.baseUrl}resources/${id}/sources/images/scan/`;
  }
  const dimensions = await loadImageDimensions(`${resourcesUrl}${syncFormat.pages[0].image}`);
  const staves = StaffParser.parseFormat([
    { top: 0, bottom: dimensions.height * (syncFormat.pages.length) },
  ], dimensions.height * (syncFormat.pages.length) as Pixel);

  const pages: Array<Image3DPageData> = [];
  syncFormat.pages.forEach((page, pageIndex) => {
    pages.push({
      image: `${resourcesUrl}${page.image}`,
      height: dimensions.height,
      rows: [],
    });
    page.rows.forEach((row) => {
      const parsedStaves: Staff[] = row.staves?.map((s, index) => ({
        top: s.top as Pixel,
        bottom: s.bottom as Pixel,
        height: s.bottom - s.top as Pixel,
        id: index + 1,
        type: StaffType.staff,
        name: 'default',
      })) || [];
      pages[pageIndex].rows.push({
        staves: [
          {
            top: row.y as Pixel,
            bottom: (row.y + row.height) as Pixel,
            height: row.height as Pixel,
            id: 0,
            type: StaffType.line,
            name: 'all',
          },
          ...parsedStaves,
        ],
        y: row.y as Pixel,
        height: row.height as Pixel,
        end: row['end-x'] as Pixel,
        start: row['start-x'] as Pixel,
      });
    });
  });

  return SourceParser
    .fromFormat(format, dimensions, pixel3DUnit, staves, imageUrl, undefined, pages);
}

/**
 * Load an audio image source (spectrogram) using info from the Piece object
 * @param this the dezrann client
 * @param format the format of the audio image info, from the SourceFormat
 * @param secondUnit the onset-to-second unit of the parent track
 * @param id the id of the piece
 * @returns a complete Source object
 */
async function loadAudioImageSource(
  this: IClient,
  format: AudioImageFileFormat,
  track: Audio,
  id: string,
): Promise<Source> {
  const timeToPixelUrl = this.url(`${this.staticRoute}/${id}`, format.positions).data;

  const timeToPixel = (await this.get<AudioImageSyncFormat>(timeToPixelUrl)).data;

  const pixelUnit = UnitParser.createAudioImageUnit(track.secondUnit, timeToPixel);

  const imageUrl = this.url(`${this.staticRoute}/${id}`, format.image).data;

  const dimensions = await loadImageDimensions(imageUrl);

  const staves = StaffParser.parseFormat(timeToPixel.staffs, dimensions.height);

  return SourceParser.fromFormat(format, dimensions, pixelUnit, staves, imageUrl, track, [{
    height: dimensions.height,
    rows: [{
      staves,
      y: staves[0].top,
      height: staves[0].height,
      end: dimensions.width,
      start: 0 as Pixel,
    }],
  }]);
}

/**
 * Load an audio track using info from the piece object
 * @param this The dezrann Client
 * @param format the format of the audio info, from the SourceFormat
 * @param unit the onset-to-second unit of the track, pre-loaded by loadAudioSources
 * @param id of the piece
 * @returns
 */
function loadAudio(
  this: IClient,
  format: AudioFileFormat,
  secondUnit: Unit<Second>,
  id: string,
): Audio {
  let url: string | undefined;
  let ytId: string | undefined;

  // Using the object structure, load the audio id from the backend or youtube
  if ('yt-id' in format && format['yt-id']) {
    ytId = format['yt-id'];
  }
  if ('file' in format && format.file) {
    url = this.url(`${this.staticRoute}/${id}`, format.file).data;
  }

  if (!ytId && !url) {
    throw ClientResponse.fromParsingError(new Error('Invalid Audio Format: missing source'));
  }

  return AudioParser.fromFormat(secondUnit, url, ytId, format.info);
}

export interface AudioSources {
  track: Audio;
  images: Source[];
}
async function loadAudioSources(
  this: IClient,
  format: AudioFileFormat,
  id: string,
): Promise<AudioSources> {
  const audioSyncUrl = format['onset-date'];

  if (!audioSyncUrl) {
    throw ClientResponse.fromParsingError(new Error('Invalid Audio Format: no sync url'));
  }

  const onsetToTimeUrl = this.url(`${this.staticRoute}/${id}`, audioSyncUrl).data;

  const onsetToTime = (await this.get<AudioSyncFormat>(onsetToTimeUrl)).data;

  const secondUnit = UnitParser.createAudioUnit(onsetToTime);

  const track = loadAudio.bind(this)(format, secondUnit, id);

  const imagePromises = format
    ?.images
    ?.map((imageFormat) => {
      const newFormat = imageFormat;
      newFormat.license = format.license ?? '';
      newFormat.info = format.info ?? '';
      return loadAudioImageSource.bind(this)(newFormat, track, id);
    })
    ?? [];

  const imagesSettled = await Promise.allSettled(imagePromises);

  const images = filterSettled(imagesSettled);

  return { images, track };
}

export interface PieceSources {
  sources: Source[];
  tracks: Audio[];
}

export async function loadPieceSources(
  this: IClient,
  format: PieceFormat,
  path: string,
): Promise<PieceSources> {
  // Get promises for all scores
  const scoreSourcePromises: Promise<Source>[] = format
    ?.sources
    ?.images
    ?.map((imageFormat) => {
      if (imageFormat.type === SourceType.Image3D) {
        return loadImage3DSource.bind(this)(imageFormat, path);
      }
      return loadImageSource.bind(this)(imageFormat, path);
    })
    ?? [];

  // Get promises for all audio files
  const audioSourcePromises: Promise<AudioSources>[] = format
    ?.sources
    ?.audios
    ?.map((audioFormat) => loadAudioSources.bind(this)(audioFormat, path))
    ?? [];

  // Await and filter results

  const scoresSourcesSettled = await Promise.allSettled(scoreSourcePromises);
  const scoreSources = filterSettled(scoresSourcesSettled);

  const audioSourceSettled = await Promise.allSettled(audioSourcePromises);
  const audioSources = filterSettled(audioSourceSettled);

  const tracks = audioSources.map((s) => s.track);

  const audioImageSources = audioSources.map((s) => s.images).flat();

  const sources = [
    ...scoreSources,
    ...audioImageSources,
  ];

  sources.forEach((s, index) => s.setId(index));

  return { sources, tracks };
}

export function generateGridSource(measureCount: number, breakPoints: Array<Onset>): Source {
  const rowCount = measureCount / 4;
  const pageCount = breakPoints.length + 1;

  const unitPages: Image3DSyncFormat = {
    pages: [{
      image: '',
      rows: [],
    }],
  };
  const imagePages: Image3DPageData[] = [{
    height: (rowCount / pageCount) * 100,
    rows: [],
  }];

  let currentPage = 0;
  let currentBreakpoint = 0;

  for (let y = 0; y < rowCount; y += 1) {
    const onsetX: UnitCoupleFormat<'onset' | 'x'> = [];

    // WIP - Create new pages based on Onset breakpoints
    //
    if (y * 16 >= breakPoints[currentBreakpoint]) {
      currentBreakpoint += 1;
      currentPage += 1;
      unitPages.pages.push({
        image: '',
        rows: [],
      });
      imagePages.push({
        height: (rowCount / pageCount) * 100,
        rows: [],
      });
    }

    for (let x = 0; x <= 16; x += 1) {
      const onset = (y * 16) + (x) as Onset;
      onsetX.push({
        onset,
        x: (x * 62.5) + 150,
      });
    }

    unitPages.pages[currentPage].rows.push({
      'onset-x': onsetX,
      'start-x': 150,
      'end-x': 1150,
      y: (y * 100) - ((currentPage) * 200),
      height: 100,
    });

    imagePages[currentPage].rows.push({
      start: 150 as Pixel,
      end: 1150 as Pixel,
      y: (y * 100) - ((currentPage) * 200) as Pixel,
      height: 100 as Pixel,
      staves: [{
        id: 0, top: (100 * y) - ((currentPage) * 200) + 10 as Pixel, bottom: (100 * y) - ((currentPage) * 200) + 90 as Pixel, height: 80 as Pixel, name: 'all', type: StaffType.line,
      }, {
        id: 1, top: (100 * y) - ((currentPage) * 200) + 10 as Pixel, bottom: (100 * y) - ((currentPage) * 200) + 15 as Pixel, height: 5 as Pixel, name: 'top.1', type: StaffType.line,
      }, {
        id: 2, top: (100 * y) - ((currentPage) * 200) + 15 as Pixel, bottom: (100 * y) - ((currentPage) * 200) + 20 as Pixel, height: 5 as Pixel, name: 'top.2', type: StaffType.line,
      }, {
        id: 3, top: (100 * y) - ((currentPage) * 200) + 20 as Pixel, bottom: (100 * y) - ((currentPage) * 200) + 25 as Pixel, height: 5 as Pixel, name: 'top.3', type: StaffType.line,
      }, {
        id: 4, top: (100 * y) - ((currentPage) * 200) + 25 as Pixel, bottom: (100 * y) - ((currentPage) * 200) + 30 as Pixel, height: 5 as Pixel, name: 'bot.1', type: StaffType.line,
      }, {
        id: 5, top: (100 * y) - ((currentPage) * 200) + 30 as Pixel, bottom: (100 * y) - ((currentPage) * 200) + 60 as Pixel, height: 30 as Pixel, name: 'bot.2', type: StaffType.line,
      }, {
        id: 6, top: (100 * y) - ((currentPage) * 200) + 60 as Pixel, bottom: (100 * y) - ((currentPage) * 200) + 90 as Pixel, height: 30 as Pixel, name: 'bot.3', type: StaffType.line,
      }],
    });
  }

  const source = SourceParser.fromFormat({
    image: '',
    positions: '',
    type: SourceType.Grid,
  }, {
    height: rowCount * 100 as Pixel,
    width: 1150 as Pixel,
  }, UnitParser.createImage3DUnit(unitPages), [{
    id: 0, top: 0 as Pixel, bottom: 0 as Pixel, height: 0 as Pixel, name: 'all', type: StaffType.line,
  }], '', undefined, imagePages);
  return source;
}
