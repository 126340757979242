import type { CompareFn } from 'sorted-array-functions';
import {
  add, remove, lt, gt, has,
} from 'sorted-array-functions';
import type { Label, LabelId, LabelPredicate } from '@/data';

type CmpReturn = ReturnType<CompareFn>;

export class SortedLabels {
  labels!: Label[];

  labelsRef: Record<LabelId, Label>;

  constructor(labels: Record<LabelId, Label>) {
    this.labelsRef = labels;
    this.reset();
  }

  public reset(): void {
    this.labels = Object.values(this.labelsRef);
    this.update();
  }

  public update(): void {
    this.labels.sort(SortedLabels.compare);
  }

  public add(label: Label): void {
    // Check if label is not already in it
    if (this.has(label)) {
      // If it is, warn and reset the db
      this.reset();
      // eslint-disable-next-line no-console
      console.warn('DB reset to prevent de-sync: ', label, 'already in db');
      return;
    }
    add(this.labels, label, SortedLabels.compare);
  }

  public has(label: Label): boolean {
    return has(this.labels, label, SortedLabels.compare);
  }

  public prev(label: Label | null, predicate?: LabelPredicate): Label | null {
    let index: number;

    // search for previous label of input
    if (label) index = lt(this.labels, label, SortedLabels.compare);
    // If no label provided, return last label;
    else index = this.labels.length - 1;

    // If a predicate was given
    // Move backwards until the predicate is true or the start is reached
    while (predicate && this.labels[index] && !predicate(this.labels[index])) {
      index -= 1;
    }

    // All else fails, return null
    return this.labels[index] || null;
  }

  public next(label: Label | null, predicate?: LabelPredicate): Label | null {
    let index: number;

    // Otherwise search for previous label of input
    if (label) index = gt(this.labels, label, SortedLabels.compare);
    // If asking for next label of null, return first label;
    else index = 0;

    // If a predicate was given
    // Move forwards until the predicate is true or the end is reached
    while (predicate && this.labels[index] && !predicate(this.labels[index])) {
      index += 1;
    }

    // All else fails, return null
    return this.labels[index] || null;
  }

  public remove(label: Label): void {
    // Try to remove a label
    if (!remove(this.labels, label, SortedLabels.compare)) {
      // If it fails, warn and reset the db
      this.reset();
      // eslint-disable-next-line no-console
      console.warn('DB reset to prevent de-sync: ', label, 'not in db');
      return;
    }
    // Check if label is removed
    if (this.has(label)) {
      // If it is, warn and reset the db
      this.reset();
      // eslint-disable-next-line no-console
      console.warn('DB reset to prevent de-sync: ', label, 'not removed in db');
    }
  }

  public filter(predicate: LabelPredicate): Label[] {
    return this.labels.filter(predicate);
  }

  static toCMP(a: number): CmpReturn {
    if (!a) return 0;
    if (a < 0) return -1;
    return 1;
  }

  /**
   * Determines the expected order of two labels, like localeCompare
   * Only returns 0 if the two labels have the same id
   */
  static compare(a: Label, b: Label): CmpReturn {
    if (a.onset !== b.onset) return a.onset - b.onset as CmpReturn;
    if (a.staffId !== b.staffId) return a.staffId - b.staffId as CmpReturn;
    if (a.duration !== b.duration) return b.duration - a.duration as CmpReturn;
    return a.id - b.id as CmpReturn;
  }
}
