import type { Module } from 'vuex';
import type { AnalysisStoreState } from './analysis.state';
import { state } from './analysis.state';
import { mutations } from './analysis.mutations';
import { actions } from './analysis.actions';

export const analysisModule: Module<AnalysisStoreState, AnalysisStoreState> = {
  state,
  mutations,
  actions,
  namespaced: true,
};
