import type { Response } from './ClientResponse';
import { ClientResponse } from './ClientResponse';
import type { IClient } from './IClient';

interface LoginResponse {
  // eslint-disable-next-line camelcase
  access_token: string;
}

export class User {
  name: string;

  token: string;

  constructor(name: string, token: string) {
    this.name = name;
    this.token = token;
  }

  static fromString(json: string | null): User | null {
    if (!json) return null;
    const { name, token } = JSON.parse(json) as Partial<User>;
    if (typeof name !== 'string') return null;
    if (typeof token !== 'string') return null;
    return new User(name, token);
  }

  toString(): string {
    return JSON.stringify(this);
  }
}

export async function login(this: IClient, uid: string, password: string): Promise<Response<User>> {
  if (!this.authUrl) throw new Error('No auth url for this client');
  const url = this.authUrl;
  const res = await this.post<LoginResponse>(url, {
    uid,
    password,
  });

  if (res.isErr()) return res.cast();

  return ClientResponse.fromData(new User(uid, res.data.access_token));
}

export async function changePassword(
  this: IClient,
  uid: string,
  oldpassword: string,
  newpassword: string,
): Promise<Response<User>> {
  if (!this.changePwdUrl) throw new Error('No change password url for this client');
  const url = this.changePwdUrl;
  const res = await this.post<LoginResponse>(url, {
    uid,
    oldpassword,
    newpassword,
  });

  if (res.isErr()) return res.cast();

  return ClientResponse.fromData(new User(uid, res.data.access_token));
}
